.driver_detail {
    display: flex;
    flex-direction: column;
    justify-content: center;


    .parcel_content {
        width: 100%;

        
        ion-searchbar{
            input{
              padding-left: 20px !important;
              padding-right: 0px !important;
              border-radius: 5px !important;
              &::placeholder{
                font-size: 15px !important;
              }
            }
            ion-icon{
              padding: 0 10px;
              right: 0 !important;
              left: auto !important;
              border-radius: 0 5px 5px 0;
              background: var(--ion-color-primary);
              clip-path: polygon(18% 0, 100% 0, 100% 100%, 0% 100%);
              color: #fff;
              top: 0;
              width: 21px;
              height: 100%;
            }
          }
        .search-icon{
            padding: 20px;
            position: absolute;
            top: 0;
            right: 0;
          }
        .scan-icon{
            position: absolute;
            top: 9px;
            right: 50px;
            font-size: 25px;
            color: #868686;
        }  
        ion-icon.scan-icon.ios{
            top: 18px;
        }
        Ion-Grid {
            padding: 0;

            .main-wrapper-block{
                margin-top: 20px;

                .main-block{
                    margin-bottom: 40px;
    
                    .main-block-title{
                        border-bottom: 1px solid #999;
                        color: #555;
                    }
    
                    .no-title{
                        color: #999;
                    }
                    
                    .week-title{
                        color: #666;
                    }
                }
            }

            .heading {
                padding-bottom: 14px;
                padding-left: 5px;
            }

            .parcel-detail {
                border: 1px solid var(--ion-color-primary);
                border-radius: 12px;
                margin-bottom: 24px;
                display: flex;
                width: 100%;
                margin: auto;
                margin-bottom: 20px;

                .sidebar-color {
                    background-color: var(--ion-color-primary);
                    border-radius: 10px 0px 0px 10px;
                }

                .content {
                    padding: 10px 10px 10px 44px;
                    background-color: #fff;
                    border-radius: 12px;
                    h3 {
                        margin: 0;
                    }

                    .parcel {
                        p {
                            position: absolute;
                            content: "";
                            top: 15%;
                            background: white;
                            left: -11%;
                            border-radius: 50%;
                            padding: 13px 13px;
                            font-size: 18px;
                            box-shadow: rgb(0 0 0 / 31%) 4px 4px 10px;
                        }
                        .type-img {
                            width: 32px;
                        }
                    }
                }
            }
        }
    }
}
