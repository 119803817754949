
.choices_wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 100px 20px 0;
    .choices_text{
        width: 100%;
        .text{
            padding: 30px 10px;
        }
        .choices_btn{
            display: flex;
            flex-direction: column;
            .booking_btn{
                width: 100%;
                text-transform: capitalize;
                --border-radius:24px;
                margin-bottom: 12px;
            }
            ion-buttons{
                .login_btn{
                    padding: 12px;
                    width: 100% !important;
                    text-transform: capitalize !important;
                    border-radius: 24px !important;
                    background: transparent !important;
                    color: var(--ion-color-primary) !important;
                    border: 3px solid !important;
                    font-size: 15px;
                    font-weight: 700;
                }
            }
        }
    }
}

// media query//
@media screen and (min-width:1024px){
    .choices_wrapper{
        .image_portion{
            ion-img{
                width: 490px;
            }
        }
    }
}