.manage-customer-wrapper {
    padding: 120px 20px 20px;

    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // /* align-items: center; */
    // padding: 20px;
    // height: 70vh;
    ion-searchbar {
        input {
            padding-left: 20px !important;
            padding-right: 0px !important;
            border-radius: 5px !important;

            &::placeholder {
                font-size: 15px !important;
            }
        }

        ion-icon {
            padding: 0 10px;
            right: 0 !important;
            left: auto !important;
            border-radius: 0 5px 5px 0;
            background: var(--ion-color-primary);
            clip-path: polygon(18% 0, 100% 0, 100% 100%, 0% 100%);
            color: #fff !important;
            top: 0 !important;
            width: 21px;
            height: 100% !important;
        }
    }

    .search-icon {
        padding: 20px;
        position: absolute;
        top: 0;
        right: 0;
    }

    .no-customer {
        text-align: center;
        margin-top: 60px !important;
    }

    h2 {
        text-align: left;
    }

    ion-popover::part(content) {
        --width: 35% !important;
    }

    .customer-card {
        box-shadow: rgb(17 17 26 / 10%) 0px 4px 16px, rgb(17 17 26 / 10%) 0px 8px 24px, rgb(17 17 26 / 10%) 0px 16px 56px;
        padding: 20px 15px;
        border-radius: 12px;
        margin: 20px 0 0 0px !important;

        .id-content {
            display: flex;
            align-items: center;

            ion-label {
                text-transform: uppercase;
                font-size: 15px;
                font-weight: 500;
            }

            p {
                margin-left: 5px;
                color: #0B58AB;
            }
        }

        .customer-content {
            padding-top: 14px;

            .user-content {
                display: flex;
                align-items: center;

                ion-avatar {
                    margin-right: 10px;
                    width: 60px;
                    height: 60px;
                }

                .ion-btn {

                    .active-btn {
                        --background: #D6F4E6;
                        color: #30AF74;
                        font-weight: 700;
                    }

                    .non-active-btn {
                        --background: #F4D8D6;
                        color: #AF3530;
                        font-weight: 700;
                    }
                }
            }


        }
    }
}