.auth-wrapper{
    padding: 145px 35px 35px 35px;

}

.auth-wrapper-inner{
    position: relative;
    .subTitle{
            margin-bottom: 40px;
    }
    ion-item{
        margin-bottom: 20px;
        border-bottom: 1px solid #d2d2d2;
        --border-style: none;
        position: relative;
        --padding-right: 0;
        .input-wrapper{
            position: relative;
            width: 100%;
            padding-right: 25px;
            ion-input{
                font-weight: 600;
                margin: 5px 0;
            }
        }
        .floating-label {
            font-size: 13px;
            color: #d2d2d2;
            position: absolute;
            pointer-events: none;
            top: 19px;
            left: 0;
            transition: all 0.5s ease-in-out;
        }
        .top-label {
            font-size: 11px;
            opacity: 1;
            color: #000;
            position: absolute;
            pointer-events: none;
            top: 4px;
            left: 0;
            transition: all 0.5s ease-in-out;
        }
        span{
            margin-left: auto;
            h6{
                color: #FECA2D;
                margin-bottom: 0px;
                margin-right: 10px;
            }
        }
        ion-icon{
            margin: 0 10px;
        }
        .eye-icon{
            margin-right: 10px !important;
            margin-left: auto;
        }
    }
    .item-shadow{
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        border-bottom: none;
    }
    ion-button{
        margin-top: 35px;
      
        ion-icon{
            margin-left: 10px;
        }
    }
}
.auth-footer{
    span{
    color: var(--ion-text-primary);
}
    a{
        text-decoration: none;
    }
}
ion-item::part(native) {
    --padding-start: 0 !important;
    --inner-padding-end: 0px !important;
}
.desktop-auth-wrapper{
    padding: 35px 35px 0 0;
    h4{
        span{
            padding: 0 8px;
            background-color: #fff;
            &::before{
                content: "";
                position: absolute;
                height: 1px;
                width: 100%;
                top: 4px;
                left: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                background: black;
                z-index: -1;
            }
    }
}
ion-button{
    margin: 25px 0;
    display: block;
    ion-icon{
        margin-left: 10px;
    }
}
}
.desktop-auth-grid{
    display: flex;
    align-items: center;
    min-height: 88vh;
}
@media screen and (max-width: 991px) {
    .desktop-auth-grid{
        display: block;
    }
    .desktop-auth-wrapper{
        padding: 35px 0 0 0;
    }
    .image-col{
            display: none !important;
    }
  }