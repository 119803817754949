.setting-wrapper {
    padding: 120px 30px 0px 30px;
    background: white;
    height: 100%;

    .setting-heading {
        text-align: center;
        padding-bottom: 10px;
    }

    Ion-Accordion-Group {
        Ion-Accordion {
            Ion-Item {
                padding: 0px !important;
                // color: var(--ion-color-primary);
            }

            Ion-Icon {
                font-size: 18px !important;
                // color: var(--ion-color-primary);
            }

            .accordion-content {
                font-size: 14px;
                // color: var(--ion-color-primary);
            }
        }
    }
}

.accordion-expanded ion-label {
    font-weight: 500 !important;
    font-size: 14px !important;
    padding-left: 10px;
}

Ion-Accordion.accordion-expanded Ion-Item[slot=header] Ion-Icon {
    font-size: 18px !important;
    color: black !important;
}
