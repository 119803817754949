.ion-page-invisible {
    opacity: 1;
}

.pointer {
    cursor: pointer;
}

.toolbar-title {
    display: block;
    width: 50%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    pointer-events: auto;
}

.toolbar-background {
    background: none !important;
}

.toolbar-container {
    padding: 3px !important;
}

ion-header {
    display: block;
    position: initial;
    -ms-flex-order: -1;
    width: 100%;
}

.scroll-y {
    padding: 0 !important;
}

-ion-buttons-ios-h {
    display: initial !important;
}

#main-content {
    .menu {
        .iontolbar {
            display: flex;
            justify-content: end;
            padding: 0px 12px 0px 0px;
            // .notification {
            //     display: flex;
            //     justify-content: end;
            //     margin-right: 10px;

            //     .notifications-icon-btn {
            //         font-size: 25px;
            //         background: white;
            //         padding: 12px;
            //         border-radius: 50%;
            //         box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
            //         display: flex;


            //         &::after {
            //             content: "";
            //             width: 9px;
            //             height: 9px;
            //             border-radius: 50%;
            //             position: absolute;
            //             background: var(--ion-color-primary);
            //             top: 16px;
            //             right: 15px;

            //         }
            //     }
            // }

            .ion-btn {
                border-radius: 50%;
                box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
                background-color: white;

                ion-img {
                    width: 40px;
                    padding: 1px;

                }
            }

        }
    }

    .notification {
        display: flex;
        justify-content: end;
        margin-right: 20px;
        position: relative;
        ion-badge{
            position: absolute;
            right: 14px;
            top: 15px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 10px;
            z-index: 999;
        }
        ion-icon{
            font-size: 25px;
            background: white;
            padding: 12px;
            border-radius: 50%;
            box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
            display: flex;
        }
        .notifications-icon-btn {
            &::after {
                content: "";
                width: 9px;
                height: 9px;
                border-radius: 50%;
                position: absolute;
                background: var(--ion-color-primary);
                top: 16px;
                right: 15px;

            }
        }
    }

}

// .notification {
//     display: flex;
//     justify-content: end;
//     margin-right: 10px;
//     position: absolute;
//     right: 62px;
//     top: 9px;
//     content: "";

//     .notifications-icon-btn {
//         font-size: 25px;
//         background: white;
//         padding: 12px;
//         border-radius: 50%;
//         box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
//         display: flex;


//         &::after {
//             content: "";
//             width: 9px;
//             height: 9px;
//             border-radius: 50%;
//             position: absolute;
//             background: var(--ion-color-primary);
//             top: 16px;
//             right: 15px;

//         }
//     }
// }


// ion-menu-button {
//     width: 30px;

// }


// sidebar css

.driver-sidebar-profile {
    padding: 50px 30px 0px 30px;
    background-color: var(--ion-color-tertiary);
    height: 100%;


    .driver-profile {
        display: flex;
        align-items: center;
        margin-right: auto;
        // margin: 0px 0px 0px 20px;


        .avatar-img {
            display: flex;
            align-items: center;
            width: 80px;

            ion-img {
                width: 80px !important;
                height: 80px !important;
            }

        }

        .profile-content {
            margin-left: 25px;

            h4 {
                color: white;
            }

            p {
                color: white;
            }
        }
    }

    .side-profile-list {
        padding: 60px 0 0 0 !important;

        ion-icon {
            // color: var(--ion-color-tertiary);
            color: white;
        }

        ion-label {
            margin-left: 15px;
            // color: black;
            color: white;
        }

    }
}