.all-orders-wrapper{
    padding: 100px 20px 0;
    ion-item {
        --background: none;
        opacity:1;
      }
}
.item-lists{
display: flex;
align-items: center;
ion-item{
    background-color: #fff;
}
.select-option {
    width: 50%;
    // margin-left: auto;
    margin: 0px 5px 10px 5px;
    --background: transparent;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 12px;
}
}