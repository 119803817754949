.desktop-input {
    ion-grid {
        padding: 0;
        .rightbar {
            .inner-rightbar {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
            }
            .input_rightbar_content {
                .input_card {
                    width: 100%;
                    display: flex;
                    margin: auto;
                    justify-content: center;
                    position: relative;
                    // margin: 20px 60px;
                    .input-content {
                        background-color: var(--ion-color-primary);
                        border-radius: 20px 0 0 20px;
                        position: relative;
                        padding: 0;
                        .ion-icon {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            text-align: center;
                            height: 100%;
                            margin: 0px 67px;
                            ion-img {
                                width: 65px;
                          
                            }
                            .Map-loction-icon {
                                font-size: 50px;
                                color: white;
                             
                            }
                        }
                        .input-img {
                            content: "";
                            position: absolute;
                            top: 24%;
                            left: 55%;
                            z-index: 99;
                            ion-img {
                                width: 160px;
                            }
                        }
                    }
           
                    .contact-form {
                        background-color: white;
                        padding: 40px 120px;
                        border-radius: 0 20px 20px 0;
                        p {
                            text-align: center;
                            padding-bottom: 5px;
                            font-size: 20px;
                            line-height: 1.2;
                            span {
                                font-weight: 700;
                                font-size: 20px;
                                color: black;
                            }
                        }
                        .input-contact-form {
                            .label-setting {
                                margin-left: 7px;
                                font-size: 16px;
                                font-weight: 600 !important;
                            }
                            .input-form-box {
                              
                                .street-input-address {
                                    width: 100%;
                                    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                                    margin: 10px 8px 20px 0px;
                                    border-radius: 15px;
                                    padding-left: 15px;
                                    padding-right: 15px;
                                }
                          
                            }
                        }
                        .input-form-box {
                            // display: flex;
                            .street-input-address-form {
                                width: 100%;
                                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                                margin: 10px 8px 20px 0px;
                                border-radius: 10px;
                                padding-left: 15px;
                                // height: 50px;
                                .text-area {
                                    --padding-top: 0;
                                }
                            }
                            .check-box-content {
                                display: flex;
                                margin-top: 30px;
                                align-items: center;
                                ion-checkbox {
                                    width: 25px;
                                    height: 15px;
                                    margin-top: -15px;
                                }
                                .check-box-disc {
                                    font-size: 15px;
                                    padding-left: 12px !important;
                                    span {
                                        font-size: 15px;
                                        color: black;
                                        font-weight: 700;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .input-btn {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        ion-button {
            width: 150px;
        }
    }
}
.html_input {
    width: 100%;
    border: inherit;
    padding: 0px 6px;
    background: transparent;
    outline: none;
    font-size: 18px;
    font-weight: 500;
  }
@media (max-width: 1024px) {
    .desktop-input {
        .input_rightbar_content {
            .input_card {
                width: 100% !important;
                .input-content {
            
                    .input-img {
                        left: 48% !important;
                    }
                }
                .contact-form {
                    padding: 40px 80px !important;
                    .input-form-box {
                        .check-box-content {
                            ion-checkbox {
                                margin-top: -22px !important;
                                width: 32px !important;
                         
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 912px) {
    .desktop-input {
        .input_rightbar_content {
            .input_card {
                width: 100% !important;
            
                .contact-form {
                    padding: 40px 80px !important;
                    .input-form-box {
                        .check-box-content {
                            ion-checkbox {
                                margin-top: -22px !important;
                                width: 18px !important;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 767px) {
    .desktop-input {
        .input_rightbar_content {
            border-radius: 25px 25px 0 0px !important;
            .input_card {
                width: 90% !important;
                .input-content {
                    border-radius: 20px 20px 0 0 !important;
                    .input-img {
                        left: 27% !important;
                        top: -86% !important;
                    }
                }
                .contact-form {
                    padding: 40px 20px !important;
                    .input-contact-form {
                        .input-form-box {
                            display: flex;
                            .input-field {
                                width: 46% !important;
                                border: 1px solid var(--ion-color-tertiary);
                                margin: 10px 8px 20px 0px;
                                border-radius: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 767px) {
    .desktop-input {
        .input_rightbar_content {
            .input_card {
                width: 100% !important;
           
                .contact-form {
                    padding: 40px 30px !important;
                    .input-form-box {
                        .check-box-content {
                            ion-checkbox {
                                margin-top: -22px !important;
                                width: 30px !important;
                                // margin-top: -35px !important;
                            }
                        }
                        .input-form-box {
                            display: flex;
                            .input-field {
                                width: 46% !important;
                                border: 1px solid var(--ion-color-tertiary);
                                margin: 10px 8px 20px 0px;
                                border-radius: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}
.sc-ion-textarea-md-h {
    --padding-top: 4px;
}