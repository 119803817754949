.meetup-header {
    background-color: #f9f9f9 !important;
    &::before {
      background: var(--ion-color-secondary);
      content: " ";
      padding: 110px;
      clip-path: circle(85.8% at -36% 15%);
    }
    .header_back_button {
      --background: white !important;
      --border-radius: 50% !important;
      --box-shadow: rgb(0 0 0 / 16%) 0px 4px 22px;
      width: 60px;
      height: 60px;
      position: absolute;
      left: 23px;
      top: 46px;
      ion-icon {
        color: #595959cc;
        font-size: 24px;
      }
    }
  }

.meetup-scan-wrapper{
  padding-top: 120px;
  .meetup-cards-wrapper{
    padding: 0 20px;
    .meetup-card{
        background-color: #fff;
        border: 1px solid #F8F8FD;
        border-top: 2px solid var(--ion-color-primary);
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        border-radius: 5px;
        padding: 20px;
        margin-bottom: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        ion-icon{
            color: var(--ion-color-tertiary);
        }
        h6{
            color: #827d7d;
        }
    }
  }
}