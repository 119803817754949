.customer-care-wrapper{
    background-color: #F3F3F3;
    height: 100%;
 
       .customer-care-content{
        padding:20px 14px;
             .back-icon-content{
                display: flex;
                align-items: center;
                margin-bottom: 30px;
                    ion-icon{
                        font-size: 25px;
                    background: white;
                    padding: 12px;
                    border-radius: 50%;
                    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
                    display: flex;
                    }
                    ion-label{
                        font-weight: 600;
                        font-size: 20px;
                        margin-left: 16px;
                    }
             }
             ion-img{
                max-width: 400px;
                margin: auto;
             }
             Ion-Grid{
                margin-top: 20px;
                padding: 0;
                Ion-Row{
                    Ion-Col{
                       .contact-boxes{
                        background-color: white;
                        text-align: center;
                        padding: 12px 4px;
                        border-radius: 10px;
                        ion-icon{
                            font-size: 25px;
                        padding: 12px;
                        border-radius: 50%;
                        background-color: var(--ion-color-success);
                        color: white;
                        // box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
                    
                        }
                        h6{
                            padding: 5px 0px;
                        }
                        
                       } 
                    }   
                }
             }
       }

       .contact-form{
        background-color: white;
        padding: 20px;
        border-radius: 40px 40px 0px 0px;
        h3{
            text-align: center;
        }

        .street-input-address-form{
            .input-form{
                width: 98%;
                margin-bottom: 20px;
                Ion-Label{
                    margin-bottom: 8px;
                    font-weight: 700;
                    span{
                        color: var(--ion-color-success);
                        font-size: 20px;
                        position: relative;
                        top: 2px;
                        left: 2px;
                    }
                }
                .input-field{
                    // border: 2px solid var(--ion-color-primary);
                    border-radius: 15px;
                    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
                    margin: 0px 3px;
                }
                .text-area{
                    // border: 2px solid var(--ion-color-primary);
                    border-radius: 15px;
                    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
                    margin: 0px 3px;
                }
            } 
            &:nth-child(3){
                .input-form{
                Ion-Label{       
                    &::before{
                        left: 27%;
                    }
                }
            }
            }
        }
  
        .form-btn{
            text-align: center;
            margin-top: 12px;
            button{
                display: flex;
                margin: auto;
                background-color:  var(--ion-color-success);
                color: white;
                padding: 10px 20px;
                border-radius: 20px;
                font-size: 20px;
                
                p{
                    color: white;
                    font-size: 16px;
                }
                ion-icon{
                    margin-left: 5px;
                    font-size: 19px;
                }
            }
        }
        .sc-ion-input-md-h {
            --padding-top: 18px;
            --padding-end: 15px;
            --padding-bottom: 18px;
            --padding-start: 15px;
            font-size: inherit;
        }
        .sc-ion-input-ios-h {
            --padding-top: 18px;
            --padding-end: 15px;
            --padding-bottom: 18px;
            --padding-start: 15px;
            font-size: inherit;
        }
        .native-textarea.sc-ion-textarea-md {
            --padding-top: 18px;
            --padding-end: 15px;
            --padding-bottom: 18px;
            --padding-start: 15px;
        }
        .native-textarea.sc-ion-textarea-ios {
            --padding-top: 18px;
            --padding-end: 15px;
            --padding-bottom: 18px;
            --padding-start: 15px;
        }
       }
    
}

