.desktop-wrapper {
    // background-color: var(--ion-color-tertiary);

    ion-grid {
        padding: 0;

        .rightbar {
            .inner-rightbar {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;

                .summary-detail-rightbar-content {


                    .pickup_card {
                        width: 100%;
                        display: flex;
                        margin: auto;
                        justify-content: center;
                        position: relative;

                        .pickup-content {
                            background-color: var(--ion-color-primary);
                            border-radius: 20px 0 0 20px;
                            position: relative;
                            z-index: 1;

                            .ion-icon {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                text-align: center;
                                height: 100%;
                                margin: 0 50px;

                                ion-img {
                                    width: 65px;
                                    // transform: rotate(-10deg);
                                }

                                .Map-loction-icon {
                                    font-size: 50px;
                                    color: white;
                                    // margin: 10px 0px 0 20px;
                                }
                            }


                            .pickup-img {
                                content: "";
                                position: absolute;
                                top: 24%;
                                left: 55%;
                                z-index: 99;

                                ion-img {
                                    width: 160px;
                                }
                            }

                        }

                        .summary-order {
                            border-radius: 0 20px 20px 0;
                            text-align: center;


                            .summary-order-content {
                                padding: 40px 120px;
                                background-color: white;
                                z-index: 11;
                                position: relative;

                                .information-box {
                                    display: flex;
                                    justify-content: center;
                                    margin-top: 22px;

                                    .box1 {
                                        margin-right: 10px;
                                        border: 1px solid var(--ion-color-tertiary);
                                        padding: 10px 25px;
                                        border-radius: 15px;

                                        h3 {
                                            font-size: 20px;
                                            margin: 0;
                                        }
                                    }

                                    .box2 {

                                        margin-left: 10px;
                                        border: 1px solid var(--ion-color-tertiary);
                                        padding: 10px 25px;
                                        border-radius: 15px;

                                        h3 {
                                            font-size: 20px;
                                            margin: 0;
                                        }
                                    }
                                }

                                .pickup-btn {
                                    display: flex;
                                    justify-content: center;
                                    margin-top: 22px;

                                    ion-button {
                                        width: 150px;
                                        // padding: 25px 0;
                                    }
                                }
                            }

                            .clock-img {
                                ion-img {
                                    content: "";
                                    width: 210px;
                                    position: absolute;
                                    top: -32%;
                                    left: 33%;
                                }
                            }

                        }

                    }
                }
            }
        }

    }
}


@media (max-width: 1024px) {
    .desktop-wrapper {
        .rightbar {
            .inner-rightbar {
                margin: 0 10px;

                .summary-detail-rightbar-content {
                    .pickup_card {
                        .pickup-content {
                            border-radius: 20px 20px 0 0 !important;
                        }

                        .summary-order-content {
                            padding: 40px !important;

                            .information-box {

                                .box1 {
                                    padding: 10px 18px !important;
                                }

                                .box2 {
                                    padding: 10px 18px !important;
                                }
                            }
                        }

                        .clock-img {
                            ion-img {
                                top: -32%;
                                left: 25% !important;
                            }
                        }
                    }
                }
            }

        }
    }
}

@media (max-width: 912px) {
    .desktop-wrapper {
        .rightbar {
            .inner-rightbar {
                margin: 0 10px;

                .summary-detail-rightbar-content {
                    .pickup_card {
                        .pickup-content {
                            border-radius: 20px 20px 0 0 !important;
                        }

                        .summary-order-content {
                            padding: 40px !important;

                            .order-disc {
                                padding: 0 50px !important;
                            }

                            .information-box {

                                .box1 {
                                    padding: 10px 30px !important;
                                }

                                .box2 {
                                    padding: 10px 30px !important;
                                }
                            }
                        }

                        .clock-img {
                            ion-img {
                                top: -33% !important;
                                left: 33% !important;
                            }
                        }
                    }
                }
            }

        }
    }
}

@media (max-width: 767px) {
    .desktop-wrapper {
        .rightbar {
            .inner-rightbar {
                margin: 0 10px;

                .summary-detail-rightbar-content {
                    .pickup_card {
                        .pickup-content {
                            border-radius: 20px 20px 0 0 !important;
                            padding: 10px 0;
                        }

                        .summary-order-content {
                            padding: 40px 0px !important;

                            .order-disc {
                                padding: 0 10px !important;
                            }

                            .information-box {

                                .box1 {
                                    padding: 10px !important;
                                    margin-right: 0px !important;
                                }

                                .box2 {
                                    padding: 10px !important;
                                }
                            }
                        }

                        .clock-img {
                            ion-img {
                                top: -58% !important;
                                left: 50% !important;
                                transform: translateX(-50%);
                                // transform: translateY(-50%);
                            }
                        }
                    }
                }
            }

        }
    }
}

@media (max-width: 360px) {
    .desktop-wrapper {
        .rightbar {
            .inner-rightbar {
                margin: 0 10px;

                .summary-detail-rightbar-content {
                    .pickup_card {
                        .pickup-content {
                            border-radius: 20px 20px 0 0 !important;
                            padding: 10px 0;
                        }

                        .summary-order-content {
                            padding: 40px 0px !important;

                            .order-disc {
                                padding: 0 10px !important;
                            }

                            .information-box {

                                .box1 {
                                    padding: 10px !important;
                                    margin-right: 0px !important;
                                }

                                .box2 {
                                    padding: 10px !important;
                                }
                            }
                        }

                        .clock-img {
                            ion-img {
                                top: -56% !important;
                                left: 50% !important;
                                transform: translateX(-50%);
                                // transform: translateY(-50%);
                            }
                        }
                    }
                }
            }

        }
    }
}