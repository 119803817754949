.bookings-wrapper{
    position: relative;
    min-height: 100vh;
    background-color: #F0F5FB;
    .menu-wrapper{
        background-color: var(--ion-color-tertiary);
        padding-top: 10px;
        .back-icon{
            color: #fff;
            padding-left: 10px;
        }
    }
.bookings-header{
    background-color: var(--ion-color-tertiary);
    padding: 0 15px 50px 15px;
    clip-path: polygon(0 0, 100% 0, 100% 87%, 0% 100%);
    ion-img{
        width: 150px;
        margin: 0 auto;
        padding: 35px 0;
    }
    ion-searchbar{
        input{
            background: #fff;
        }
    }
}
.order-list{
    padding: 31px;
    .success-icon{
        background: var(--ion-color-primary);
        border-radius: 50%;
        width: 50px;
        padding: 10px;
        margin-right: 16px;
      }
      .pending-icon{
        background: var(--ion-color-tertiary);
        border-radius: 50%;
        width: 50px;
        padding: 10px;
        margin-right: 16px;
      }
      ion-item{
        --inner-padding-end: 0px;
        ion-icon{
            color: var(--ion-color-tertiary);
        }
        ion-label{
            h2{
                line-height: 30px;
                margin-bottom: 0;
            }
        }
      }
      .order-list-item{
        border: 1px solid #595959cc;
        border-radius: 10px;
        margin: 20px 0;
        background-color: #fff;
        .order-no{
            line-height: 14px;;
        }
        .order-no-number{
            line-height: 16px;
        }
        .order-status{
            color: var(--ion-color-primary);
        }
      }
}
}
