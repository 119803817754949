.notification-header {
    position: relative;
    .header_toolbar {
      &::before {
        background: var(--ion-color-secondary);
        content: " ";
        padding: 110px;
        clip-path: circle(85.8% at -36% 15%);
      }
      .header_back_button {
        --background: white !important;
        --border-radius: 50% !important;
        --box-shadow: rgb(0 0 0 / 16%) 0px 4px 22px;
        width: 60px;
        height: 60px;
        position: absolute;
        left: 23px;
        top: 46px;
        ion-icon {
          color: #595959cc;
          font-size: 24px;
        }
      }
      .header_img {
            width: 150px;
            position: absolute;
            left: 0;
      }
    }
  }
  
.notification-details-wrapper{
    padding-top: 165px;

    .avatar-img {
        display: flex;
        align-items: center;

        ion-img {
            width: 50px !important;
            height: 50px !important;
        }
    }

    .profile-content {
        margin-left: 15px;
    }
    .notification-description{
        padding: 16px;
       .notification-description-inner{
            padding: 15px;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        }
    .notificatin-img{
        width: 50px !important;
        height: 50px !important;
        }
    }
}