.rightbar {
    .pickup_standard {
        filter: grayscale(1);
        width: 55px;
        position: absolute !important;
        top: -4px;
        z-index: 1;
        left: 50%;
        transform: translateX(-50%);
    }

    .pickup_standard_orignal {
        filter: grayscale(0);
        width: 55px;
        position: absolute !important;
        top: -4px;
        z-index: 1;
        left: 50%;
        transform: translateX(-50%);
    }

    .pick_up_choice {
        width: 100%;
        height: 250px;
        --background: white;
        --border-radius: 10px;

        .btn_text {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            height: 160px;

            .text_only {
                h4 {
                    text-transform: capitalize;
                }

                p {
                    text-transform: initial !important;
                }
            }

            .time-with-mode {
                display: flex;
                justify-content: center;
                align-items: center;

                .select-time {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    ion-item {
                        border: 2px solid #ccc;
                        border-radius: 10px;
                        width: 45px;
                        height: 45px;

                        ion-input {
                            color: black !important;
                            position: absolute;
                            text-align: center;
                            top: 44%;
                            transform: translateY(-50%);
                        }
                    }

                    p {
                        margin: 0px 5px !important;
                        font-size: 19px;
                        font-weight: 700;
                        color: black;
                    }

                }

                .select-time-mode {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 10px;

                    ion-buttons {
                        border: 2px solid #ccc;
                        border-radius: 10px;
                        width: 35px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 35px;
                        font-size: 12px;
                        color: black;
                        margin: 0 5px 0 0 !important;
                    }
                }

            }

            .price {
                width: calc(100% - 32px);
                // margin-bottom: 12px;
                margin-left: 16px;
                margin-right: 16px;
                height: 44px;
                border-radius: 12px;
                background: rgb(247, 249, 251);
                -webkit-box-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                align-items: center;
                display: flex;

                h5 {
                    margin: 0 !important;
                }
            }
        }

    }
}

ion-item::part(native) {
    --padding-start: 0 !important;
}

/// media query at 992 ///

@media (min-width:992px) {
    .mt-50 {
        margin: 50px 0 0 0 !important;
    }

    .mtb-5-10 {
        margin: 50px 0 70px 0 !important;
    }

    .p-media-none {
        padding: 0 !important;
    }
}