.pending-order-list-wrapper{
    .parcel_content {
        width: 100%;
        ion-searchbar{
            input{
              padding-left: 20px !important;
              padding-right: 0px !important;
              border-radius: 5px !important;
              &::placeholder{
                font-size: 15px !important;
              }
            }
            ion-icon{
              padding: 0 10px;
              right: 0 !important;
              left: auto !important;
              border-radius: 0 5px 5px 0;
              background: var(--ion-color-primary);
              clip-path: polygon(18% 0, 100% 0, 100% 100%, 0% 100%);
              color: #fff;
              top: 0;
              width: 21px;
              height: 100%;
            }
          }
        .search-icon{
            padding: 20px;
            position: absolute;
            top: 0;
            right: 0;
          }
        .scan-icon{
            position: absolute;
            top: 9px;
            right: 50px;
            font-size: 25px;
            color: #868686;
            &+div{
                display: flex;
                justify-content: space-between;
                width: 100%;
                align-items: center;
                margin-bottom: 16px;
            }
        }  
        ion-icon.scan-icon.ios{
            top: 18px;
        }
        Ion-Grid {
            padding: 0;

            .heading {
                padding-bottom: 14px;
                padding-left: 5px;
            }
        .parcel-detail-wrapper{
            display: flex;
            align-items: center;
            width: 100%;
            margin-bottom: 20px;
            gap: 10px;
            ion-checkbox {
                --size: 32px;
                --background-checked: var(--ion-color-tertiary);
              }
              
              ion-checkbox::part(container) {
                border-radius: 6px;
                border: 2px solid var(--ion-color-tertiary);
              }
            .parcel-detail {
                border: 1px solid var(--ion-color-primary);
                border-radius: 12px;
                display: flex;
                width: 100%;
                margin: auto;

                .sidebar-color {
                    background-color: var(--ion-color-primary);
                    border-radius: 10px 0px 0px 10px;
                }

                .content {
                    padding: 10px 10px 10px 44px;
                    border-radius: 12px;
                    background-color: #fff;
                    h3 {
                        margin: 0;
                    }

                    .parcel {
                        position: absolute;
                            content: "";
                            top: 15%;
                            background: white;
                            left: -11%;
                            border-radius: 50%;
                            padding: 13px 13px;
                            font-size: 18px;
                            box-shadow: rgb(0 0 0 / 31%) 4px 4px 10px;
                        ion-img {
                            width: 32px;
                        }
                    }
                }
            }
        }
        }
    }
}