.order-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  ion-icon {
    font-size: 80px;
    color: var(--ion-color-primary);
    margin: 0 auto;
    display: block;
  }
  h5 {
    color: var(--ion-color-tertiary);
    margin-bottom: 0;
  }
  ion-button {
    display: block;
    --border-radius: 50px;
    a {
      color: #fff;
      text-decoration: none;
    }
  }
  .clr-green {
    color: #30AF74;
    text-decoration: underline;
  }
}
