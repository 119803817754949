.queries-wrapper{
    h2{
        text-align: center;
        margin-bottom: 16px;
    }
    padding: 120px 20px 0;
    .card{
        padding: 20px;
        display: flex;
        border-radius: 25px;
        margin-bottom: 16px;
        h4{
            line-height: 18px !important;
        }
        ion-avatar{
                --border-radius: 25px;
                margin-right: 16px;
                width: 100px;
        }
        p{
            text-overflow: ellipsis;
            overflow: hidden; 
            width: 200px; 
            white-space: nowrap;
            &+div{
                display: flex;
                align-items: center;
            }
        }
        span{
            color: var(--ion-color-primary);
            font-size: 14px;
            font-weight: 700;
        }
        ion-icon{
            color: var(--ion-color-primary);
            font-size: 18px;
            font-weight: 700;
            margin-left: 2px;
        }
        &.queries-unread{
            background-color: #fff;
            box-shadow: rgb(17 17 26 / 10%) 0px 4px 16px, rgb(17 17 26 / 10%) 0px 8px 24px, rgb(17 17 26 / 10%) 0px 16px 56px;
        }
        &.queries-read{
            background-color: #f5f5f5;
            border: 2px solid rgb(17 17 26 / 10%);
        }
    }
}
.customer-query-wrapper{
    padding: 34px;
    .back-btn{
        display: flex;
        align-items: center;
        ion-icon{
            font-size: 30px;
        }
        span{
            margin-left: 6px;
            font-size: 20px;
            font-weight: 600;
        }
    }
    ion-avatar{
        margin: 25px auto 25px;
        width: 120px;
        height: 120px;
    }
    .query-disc{
        h4{
            color: #595959c2;
        }
        .view-customer-txt{
            color: #30AF74;
            text-decoration: underline;
        }
    }
}