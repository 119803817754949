.user-info-wrapper{
    padding: 100px 20px 0;
    h6{
        text-align: right;
        color: var(--ion-color-primary);
        padding: 30px 0px;
    }

.imge_profile {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;


    ion-avatar {
        border: 6px dashed var(--ion-color-primary);
        width: 130px;
        height: 130px;
        padding: 2px;
        position: relative !important;

        // &::after {
        //     content: "✐";
        //     background: #0C1935;
        //     color: #BBBFC6;
        //     padding: 10px;
        //     width: 30px;
        //     rotate: 183deg;
        //     text-align: center;
        //     font-size: 25px;
        //     line-height: 30px;
        //     height: 30px;
        //     border-radius: 50%;
        //     position: absolute;
        //     bottom: -19px;
        //     left: 15px;
        //     box-shadow: white 0px 0px 0px 4px;
        // }
    }

    .edit_pencile {
        background: #0C1935;
        // color: #BBBFC6;
        padding: 10px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: absolute;
        top: 100px;
        left: 50%;
        box-shadow: white 0px 0px 0px 4px;
        transform: translateX(-50%);

        ion-img {
            width: 50px;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%) scale(-1);
        }
    }

    h3 {
        margin: 30px 0 9px !important;
        font-size: 20px;
    }

    p {
        font-size: 14px;
    }
}

.user_info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .item_detail {
        position: relative !important;
        margin-bottom: 20px !important;
        padding: 10px 0 !important;
        width: 100% !important;

        ion-label {
            font-size: 15px;
            position: absolute !important;
            font-weight: 600;
            top: -15px;
            left: 25px;
        }

        .info_item {
            background: #F2F5FC !important;
            border-radius: 25px !important;
            padding: 0 24px !important;

            ion-input {
                font-size: 14px !important;
            }
        }
    }
    ion-button{
        text-transform: capitalize;
        width: 100% !important;
    }
}

.profile_list {
    padding: 40px 0 0 0 !important;

    ion-label {
        margin-left: 15px;
    }

    .logout_item {
        ion-icon {
            color: #8080808a;
        }

        ion-label {
            color: #8080808a;
        }
    }
}

.notifications_icon {
    position: absolute;
    right: 30px;
    font-size: 26px;

    &::after {
        content: "";
        width: 12px;
        height: 12px;
        border-radius: 50%;
        position: absolute;
        background: var(--ion-color-secondary);
        top: 2px;
        right: 0px;
    }
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .customer-support {
        width: 300px !important;
    }

    p {
        margin: 35px 0 0 0 !important;
        font-size: 14px;
        line-height: 1.15;
        font-weight: 600;
        text-align: center;
    }

    ion-button {
        margin-top: 20px;
        text-transform: capitalize;
    }
}

.mtb {
    margin: 20px 0 15px !important;
}

.fs-14 {
    font-size: 24px !important;
}

// .ion-activatable {
//     --background-activated: transparent !important;
//     --background-focused: transparent !important;
//     --background-hover: transparent !important;
// }

.ion-focusable {
    --background-activated: transparent !important;
    --background-focused: transparent !important;
    --background-hover: transparent !important;
}

.accordion-animated {
    transition: all 0s cubic-bezier(0.25, 0.8, 0.5, 1) 0s !important;
}

.accordion-expanded {
    background: #8080801a !important;

    ion-label {
        font-weight: 600 !important;
        font-size: 14px !important;
    }
}

ion-accordion-group {
    ion-accordion {

        ion-item {
            padding: 10px 15px 0px !important;

            ion-label {
                font-size: 14px !important;
            }

            ion-icon {
                font-size: 15px !important;
            }
        }

        .content {
            padding: 0px 15px 10px !important;
            font-size: 14px;
            line-height: 1.20;
        }
    }

}

ion-accordion.accordion-next ion-item[slot="header"] {
    --border-width: 0px 0px 0.55px 0px !important;
}

ion-accordion.accordion-expanding ion-item[slot="header"],
ion-accordion.accordion-expanded ion-item[slot="header"] {
    --border-width: 0px 0px 0px 0px !important;

    ion-icon {
        color: var(--ion-color-primary) !important;
        font-size: 15px !important;
    }
}
.plr_none{
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.pl_10{
    padding-left: 10px !important;
}
}
.about_app_wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 90vh;
    .customer-support{
        max-width: 400px;
    }
    p{
        margin: 20px 0;
    }
}
.profile_list {
    padding: 40px 0 0 0 !important;

    ion-label {
        margin-left: 15px;
    }

    .logout_item {
        ion-icon {
            color: #8080808a;
        }

        ion-label {
            color: #8080808a;
        }
    }
}
.faq-wrapper{
    h4{
        padding-top: 40px;
    }
}