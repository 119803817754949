.customer_profile_wrapper{
    padding-top: 100px;
    .avatar-wrapper{
        margin-bottom: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;    
          ion-avatar {
              border: 6px dashed var(--ion-color-primary);
              width: 130px;
              height: 130px;
              padding: 2px;
              position: relative !important;
          }           
    }
    .timeline-tabs {
        background: #fff;
        div{
          font-weight: 600;
          div:nth-child(1){
            width: 50%;
            text-align: center;
          }
          div:nth-child(2){
            width: 50%;
            text-align: center;
          }
        }
        
      }
      .timeline-wrapper {
        position: relative;
        &::before {
          content: "";
          width: 50%;
          height: 4px;
          background: var(--ion-color-secondary);
          position: absolute;
          right: 0;
        }
    }
      .info-wrapper {
        position: relative;
        &::before {
          content: "";
          width: 50%;
          height: 4px;
          background: var(--ion-color-secondary);
          position: absolute;
          left: 0;
        }
      }
      .inner-wrapper{
        background-color: #fff;
        padding: 20px;
        margin-top: 20px;
        ion-item{
          &:last-child{
            --border-style: none !important;
          }
        }
      }
}