/** Basic style **/
body {
  letter-spacing: -0.0041em;
}

ion-item {
  --background: transparent;
}

.ion-d-flex {
  display: flex;
}

.ion-fs-14 {
  font-size: 14px !important;
}

.ion-padding-top-3 {
  padding-top: 3px;
}

.overflow-auto {
  overflow: auto;
}

.h-100 {
  height: 100vh;
}

.w-full {
  width: 100% !important;
}

ion-list {
  background: transparent !important;

  ion-item {
    --background: transparent;
    --padding-start: 0;
    --inner-padding-end: 0;
  }
}

.select-list {
  width: 40%;
  margin-left: auto;
  --background: transparent;
  // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 12px;
  border: 1px solid #80808082;
  height: 43px;
  padding: 4px;
}

.ion-padding-15-12 {
  padding: 15px 8px !important;
}

.spinner-wrapper {
  margin: 0px auto;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 47px;
  height: 47px;
  border-radius: 50%;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;

  ion-spinner {
    color: var(--ion-color-primary);
    width: 22px;
  }
}

.overflow {
  --overflow: hidden !important;
}

h1 {
  font-weight: 700;
  font-size: 34px;
  line-height: 51px;
}

h4 {
  font-size: 17px !important;
  line-height: 26px !important;
  margin: 0 !important;
  font-weight: 700 !important;
  color: #343330;
}

h6 {
  font-size: 13px !important;
  line-height: 20px !important;
  color: var(--ion-color-primary-light);
}

h1,
h2,
h6 {
  font-weight: 700 !important;
}

h5 {
  font-weight: 500 !important;
  font-size: 17px;
  line-height: 26px;
  color: #343330;
}

h2 {
  font-size: 28px;
  line-height: 42px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0 !important;
}

h6 {
  color: #343330;
}

h3 {
  font-weight: 600;
  color: #191717;
}

h1,
h2,
h6,
p,
ul {
  margin-top: 0;
  margin-bottom: 0;
}

ul {
  padding: 0;
  list-style: none;
}


p {
  color: #595959cc;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.project-wrapper {
  height: 100%;
  --background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.45) 100%), url("https://images.pexels.com/photos/9466854/pexels-photo-9466854.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1") no-repeat center /cover;
}

/** App header style **/
// ion-header {
//   padding-top: 16px;

//   ion-buttons, ion-button {
//     margin: 0 !important;
//   }
// }
.bg_transparent {
  --background: transparent !important;
}

.ion-no-padding {
  padding: 0 !important;
}

.linear_bg {
  --background: #f9f9f9 !important;
}

.driver_bg {
  --background: #ffffff !important;
}

.customer_bg {
  --background: #F0F5FB !important;
}


/** App Buttons **/
.btn {
  height: 60px;
  --border-radius: 14px !important;
  border-radius: 14px;
  font-weight: 700;
  font-size: 17px;
  line-height: 26px;
  color: var(--ion-color-step-50);
  text-transform: capitalize !important;
  --box-shadow: none;
  margin: 0;
}

.custom-p-btn {
  padding: 12px;
  border-radius: 12px;
  color: white;
  font-size: 16px;
  background: var(--ion-color-primary);
}

.btn-text {
  text-transform: capitalize !important;
}

.btn-white {
  font-weight: 500;
  --background: white;
  color: var(--ion-text-color);
  border: 1px solid var(--ion-color-step-350);
}

.btn-tertiary {
  --background: var(--ion-color-tertiary) !important;
}

.btn-transparent {
  --border-radius: 20px !important;
  border-radius: 20px;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  color: var(--ion-color-step-50);
  --background: rgba(196, 196, 196, 0.4) !important;
  backdrop-filter: blur(5px);
}

.btn-bg {
  background: var(--ion-color-primary) !important;
  color: white !important;
}

.btn-light {
  color: rgba(var(--ion-text-color-rgb), 0.36);
  --background: transparent;
}

.custom-input {
  --background: var(--ion-color-light);
  --border-radius: 14px;
  --height: 60px;

  ion-label {
    color: var(--ion-color-step-400) !important;
    --highlight-color-focused: var(--ion-color-step-400) !important;
  }

  ion-input {
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
  }
}


/** Utility Classes **/
.mb-27 {
  margin-bottom: 27px;
}

.plr-20 {
  padding: 0 20px !important;
}

.ptb-30 {
  padding: 30px 0 !important;
  margin: 0 !important;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.justify-content-end {
  display: flex !important;
  justify-content: end !important;
}

.justify-content-between {
  display: flex !important;
  justify-content: space-between !important;
}

.justify-content-center {
  display: flex !important;
  justify-content: center !important;
}

.justify-content-around {
  display: flex !important;
  justify-content: space-around !important;
}

.align-items-center {
  display: flex;
  align-items: center;
}

.btn-color {
  color: #595959cc;
}

.page_title {
  font-size: 25px;
  text-align: center;
  text-transform: capitalize;
}

.position-relative {
  position: relative;
}

.desktop-wrapper {
  background-color: var(--ion-color-tertiary);
}

.rightbar {
  background-color: #F8F8F9;
  min-height: 100vh;

  h5 {
    font-size: 20px;
    font-weight: 600 !important;
    text-align: center;
  }
}

ion-select {
  padding-right: 10px;
  padding-left: 10px;
  width: 100%;
  position: relative;
}

.phone-no-input {
  border-radius: 10px;
  background-color: var(--input-color-gray);
  height: 51px;
  padding: 0 14px !important;

  ion-img {
    width: 30px;
    height: 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
  }

  .phone-code-list {
    position: relative;

    svg {
      position: absolute;
      right: 7px;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }

  ion-input {
    padding-left: 10px !important;
  }

  input {
    padding-left: 10px !important;
  }
}

.desktop-section-height {
  min-height: 100vh;
}

.page-space {
  padding: 100px 20px;
}

.delivery-type-img {
  width: 50px;
}

@media (min-width: 992px) {
  .desktop-wrapper {
    .rightbar {
      padding: 40px 100px;
      border-radius: 25px 0 0 25px;
      // height: 100vh;
    }
  }
}

@media (max-width: 991px) {

  .rightbar-none {
    display: none !important;
  }

}


// ion-popover::part(content) {
//   --width: 35% !important;
// }