
.map-tracking-ioncontent{
    --background: transparent !important;
}

.map-modal {
    padding: 0px !important;
}

capacitor-google-map {
    display: inline-block;
    width: 275px;
    height: 400px;
}

.driver-profile-edit-wrapper {
    padding: 120px 20px 20px;

    .profile-header {
        background: #ededed;
        border-radius: 10px;
        padding: 20px;

        margin: 20px 0;
        text-align: center;

        ion-avatar {
            margin: 0 auto 13px auto;
            width: 75px;
            height: 75px;
        }

        ion-button {
            height: 25px;
        }
    }

    .time-wrapper {
        text-align: center;

        h4 {
            text-align: left;
            margin: 15px 0 !important;
        }

        ion-avatar {
            background: var(--ion-color-tertiary);
            margin: 0 auto;
            padding: 8px;
            width: 52px;
            height: 52px;
            box-shadow: rgb(11 88 171 / 30%) 0px 1px 5px 7px;

            ion-icon {
                font-size: 40px;
                color: #fff;
            }
        }

        h6 {
            margin: 20px 0 !important;
        }
    }

    .driver-documents {
        .driver-detail {
            margin: 15px 0;
            ion-col{
                padding: 2px !important;
            }
            .driver-content {
                text-align: center;
                border: 1px solid #E5E5E5;
                border-radius: 10px;
                padding: 15px 0px;
                background: #fff;
                position: relative;
                ion-img{
                    width: 50px;
                    margin: auto;
                }
                ion-button {
                    font-size: 10px;
                    box-shadow: none !important;
                }
                .accept-btn{
                    --background: var(--ion-color-tertiary);
                }
                .decline-btn{
                    --background: #FE7B60;
                }
                .remind-btn{
                    --background: var(--ion-color-step-350);
                }
            }
        }
    }


    .card {
        padding: 15px 5px;
        margin: 15px 0;
        border-radius: 7px;
        background: #ededed;

        .driver-image {
            ion-img {
                width: 70px;

                &::part(image) {
                    border-radius: 10px;
                }
            }
        }

        .driver-disc {
            display: flex;
            justify-content: space-between;

            h4 {
                margin-bottom: 0px;
                line-height: 19px !important;
            }

            ion-button {
                --background: var(--ion-color-tertiary);
                height: 28px;
                font-size: 12px;
                text-transform: capitalize;
            }
        }
    }
}

.profile-btn {
    --background: #fff !important;
    color: #343330;
    margin-bottom: 16px;
}