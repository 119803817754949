.booking-wrapper {
  padding-top: 100px;
  .wrapper-inner {
    background-color: #f9f9f9;
    .page_title {
      background-color: #fff;
      padding: 10px 0;
      margin-bottom: 10px;
    }
    .booking-background-color {
      background: #fff;
      // box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    }
    .services-wrapper {
      ion-col {
        &:first-child {
          h6 {
            color: var(--ion-color-secondary);
            margin-left: 5px;
          }
        }
      }
  
      .info-heading {
        color: var(--ion-color-primary) !important;
        margin-left: 5px;
        text-decoration: underline;
      }
    
    }
    .pickup-wrapper {
      .proof-btn{
          color: var(--ion-color-primary);
          background: #D5EFE3;
          display: inline;
          padding: 3px 6px;
          border-radius: 6px;
      }
      h6{
        &:nth-child(2){
          margin-top: 6px !important;
        }
      }
      .addresses-wrapper{
        display: flex;
        // align-items: center;
        padding: 16px 0;
        border-bottom: 1px solid var(--ion-color-step-350);
        &:last-child{
          border-bottom: none;
        }
        .previous_input{
          font-size: 18px;
          font-weight: 700;
          color: #fff;
          background: var(--ion-color-tertiary);
          padding: 6px;
          border-radius: 50%;
          border: 2px solid #fff;
          box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
          margin-right: 0px;
          width: 45px;
          display: inline-block;
          text-align: center;
        }
    }
      &::first-line {
        color: var(--ion-color-secondary);
      }
      ion-icon {
        font-size: 25px;
        color: var(--ion-color-primary);
      }
    }
    .amount-wrapper {
      h5 {
        &:nth-child(2) {
          color: var(--ion-color-primary);
        }
      }
    }
    .payment-wrapper {
      ion-button {
        display: block;
      }
      .checkmark-icon {
        color: var(--ion-color-primary);
      }
    }
  }
}
.booking-confirm-wrapper {
  padding-top: 20px;
}
.customer-info-modal {
  --width: fit-content;
  --min-width: 250px;
  --height: fit-content;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  .customer-profile{
    padding: 20px;
    span{
      padding: 3px 11px;
      background: #D3D3D3;
      color: #333333;
      border-radius: 50%;
    }
    .active-btn {
      --background: #D6F4E6;
      color: #30AF74;
      // width: 90px;
      font-weight: 700;
  }

  .non-active-btn {
      --background: #F4D8D6;
      color: #AF3530;
      font-weight: 700;
      // width: 90px;
  }
  }
}

.customer-info-modal h1 {
  margin: 0 10px;
}
