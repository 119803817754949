.addr-bx {
  padding: 20px 20px;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
}

.margin-from-top {
  margin-top: 130px;
}

.addr-detail-inp {
  --background: #fff;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  border-radius: 12px;
  margin-top: 10px;
  margin-bottom: 12px;
  padding: 1px 7px !important;
  font-size: 18px;
  font-weight: 500;
}

.mb-add-pick-meetup {
  margin-bottom: 40px;
}

.meetup-box {
  padding: 20px;
  border: 1px solid #ddd8d8;
  border-radius: 7px;
  margin-bottom: 10px;
  box-shadow: rgb(221, 216, 216) 0px 0px 10px 0px;
}

.address-txt {
  color: #005584;
}

.meetup-name-inp {
  width: 100%;
  border: none;
}

.meetup-name-inp:focus {
  outline: none;
}

.meetup-remove-btn {
  --border-radius: 50%;
  height: 48px;
  span{
    width: 17px;
    height: 17px;
  }
}

.order_details {
  padding-top: 100px !important;
  padding-bottom: 20px !important;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  height: 100%;
  overflow: scroll;
  // position: relative !important;

  ion-img {
    width: 200px;
  }
  .loc-img {
    width: 60px;
  }
  .timer {
    position: absolute !important;
    top: 50%;
    color: var(--ion-color-primary);
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .loc-addr {
    margin-left: 6px;
  }

  .delivery_input {
    box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
    padding: 2px 10px;
    border-radius: 10px;
    width: 100%;
    background: white !important;
    position: relative;
    z-index: 13;
    color: black;

    .html_input {
      width: 100%;
      border: inherit;
      padding: 0px 6px;
      background: transparent;
      outline: none;
      font-size: 18px;
      font-weight: 500;
    }
  }

  .box_style {
    width: 100%;
    margin: 14px 0 0 0;
  }

  .summary_input {
    border: 2px solid #30AF74;
    border-radius: 10px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    margin-right: 10px;

    h5 {
      margin: 0;
    }
  }

  .order_price {
    border: 2px solid #30AF74;
    border-radius: 10px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 31%;

    h5 {
      margin: 0;
    }
  }

  .scale-up-center {
    -webkit-animation: scale-up-center 0.7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: scale-up-center 0.7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }

  .address-details{
    margin-left: 58px;
  }
}

.info-bx {
  margin-bottom: 24px;
}

.info-ico {
  font-size: 25px;
  color: var(--ion-color-primary);
}

.d-flex{
  display: flex;
  align-items: center;
  gap: 6px;
}

.addr-ico {
  width: 20px;
}

.address-detail-bx {
  width: 100%;
}

.address-detail-outer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
}

.privacy-checkbox {
  display: flex;
  justify-content: center;

  ion-checkbox {
    height: 21px !important;
    width: 36px !important;
  }

  p {
    padding: 2px 7px !important;
  }
}

.scale-in-center {
  -webkit-animation: scale-in-center 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: scale-in-center 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.backdrop-effect{
  ion-backdrop {
    opacity: 1 !important;
    background: #0000007a !important;
    position: absolute !important;
    z-index: 11;
  }
}

/**
*----------autocomplete-style-----------*
**/
.pac-container {
  border-radius: 10px !important;

  .pac-item {
    border-top: 0px !important;
    font-size: 15px !important;

    .pac-item-query {
      font-size: 15px !important;

      .pac-matched {
        font-size: 15px !important;
      }
    }
  }
}
.modal-wrapper{
    padding: 20px;
    background: #f9f9f9;
    ion-label{
      font-size: 18px !important;
      font-weight: 600 !important;
    }
    ion-input{
      background: #fff;
      border-radius: 10px;
      padding: 0 20px !important;
    }
    ion-input.ios{
      margin-top: 4px !important;
      margin-bottom: 12px !important;
    }
    ion-input.md{
      margin-top: 12px !important;
      margin-bottom: 4px !important;
    }
    ion-button{
        height: 2.3em;
    }
}
.modal_input{
  h6{
    margin: 7px 0 !important;
    text-align: left;
  }
  div{
    width: 50%;
  }
}
.append_input{
  display: flex;
  align-items: center;
  margin-top: 25px;
  ion-button{
    --background: #A4A4A4;
    // color: #000;
    margin-left: 10px;
    --border-radius: 50%;
    width: 40px;
    height: 40px;
  }
}
.previous_input{
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    background: var(--ion-color-tertiary);
    padding: 8px 18px;
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
    margin-right: 10px;
}
.prev-inp {
  padding: 2px 12px !important;
}
.current_input{
  font-size: 18px;
  font-weight: 700;
  color: var(--ion-color-tertiary);
  background: #fff;
  padding: 8px 16px;
  border-radius: 50%;
  border: 2px solid var(--ion-color-tertiary);
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  margin-right: 10px;
}
/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
.detail_input{
  padding: 5px 10px !important;
}
.mb_10{
  margin-bottom:10px !important;
}
.mb_0{
  margin-bottom: 0px !important;
}
.w_100{
  width: 100% !important;
}
.mb_20{
  margin-bottom: 20px !important;
}
.mt_25{
  margin-top: 25px !important;
}