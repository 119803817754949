.customer-panel-wrapper{
    padding: 15px;
    margin-top: 10px;
    ion-searchbar{
      input{
        padding-left: 20px !important;
        padding-right: 0px !important;
        border-radius: 5px !important;
        &::placeholder{
          font-size: 15px !important;
        }
      }
      ion-icon{
        padding: 0 10px;
        right: 0 !important;
        left: auto !important;
        border-radius: 0 5px 5px 0;
        background: var(--ion-color-primary);
        clip-path: polygon(18% 0, 100% 0, 100% 100%, 0% 100%);
        color: #fff !important;
        top: 0 !important;
        width: 21px;
        height: 100% !important;
      }
    }
    .search-icon{
      padding: 20px;
      position: absolute;
      top: 0;
      right: 0;
    }
  .scan-icon{
      position: absolute;
      top: 9px;
      right: 50px;
      font-size: 25px;
      color: #868686;
  }  
  ion-icon.scan-icon.ios{
    top: 18px;
  }
    ion-button{
        width: 200px;
        margin: auto;
        display: block;
    }
    .panel_boxes {
        margin: 10px 0;
        ion-col{
          padding: 0px;
        }
       .space-box{
        padding: 13px;
       }
        ion-buttons {
          display: flex;
          flex-direction: column;
          justify-content: center;
          background: white;
          box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
          border-radius: 10px;
          padding: 10px;
          width: 100%;
          height: 110px;
          h6 {
            text-align: center;
          }
          div {
            background: transparent;
           margin-bottom: 5px;
            ion-img {
              width: 40px;
            }
          }
        }
      }
    .book-parcel{
      ion-img{
        width: 30px;
        margin-right: 10px;
      }
  }
  .spin-wrapper {
    display: flex;
    justify-content: center;
  }
}
.swiper-pagination-bullet-active {
    width: 25px;
    border-radius: 5px;
    background: var(--ion-color-tertiary);
 
}
ion-slides {
    height: 100%;
  }
.swiper-pagination-bullet {
    --bullet-background: var(--ion-color-primary);
    opacity: 1;
}
@media (min-width: 1300px) {
  .customer-panel-wrapper{
      padding-top: 0;
      margin-top: 0;
      .searchbar-search-icon.sc-ion-searchbar-md {
        left: 7px;
      }
  }
}