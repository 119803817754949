.home-header{
    position: relative;
    .header-inner{
        background: var(--ion-color-secondary);
        // clip-path: ellipse(83% 70% at 22% 13%);
        padding: 0px 0px 245px 0px;
        // clip-path: circle(79% at 40% 0);
        clip-path: circle(71.5% at 50% 10%);
    }
    .home-image {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        top: 50%;
        ion-img{
            width: 150px;
        }
    }
}

.home-wrapper{
    position: relative;
    margin-top: 25px;
    .tabs{
     padding: 10px 0;
        &::before{
            content: "";
            width: 15px;
            height: 5px;
            background: var(--ion-color-secondary);
            position: absolute;
            top:26px;
        }
   
        }
}
.home-footer{
    .btn-white{
        --border-radius: 50px;
        border: 0px;
    }
}
@media (max-width:280px) {
    .home-header{
        .header-inner{ 
            padding: 0px 0px 190px 0px;
        }
    }
}
@media (min-width:375px) {
    .home-header{
        .header-inner{ 
            padding: 0px 0px 255px 0px;
        }
    }
}
@media (min-width:390px) {
    .home-header{
        .header-inner{ 
            padding: 0px 0px 265px 0px;
        }
    }
}
@media (min-width:412px) {
    .home-header{
        .header-inner{ 
            padding: 0px 0px 280px 0px;
        }
    }
}
@media (min-width:540px) {
    .home-header{
        .header-inner{ 
            padding: 0px 0px 365px 0px;
        }
    }
}
@media (min-width:668px) {
    .home-header{
        .header-inner{ 
            padding: 0px 0px 520px 0px;
        }

}
}
@media (min-width:820px) {
    .home-header{
        .header-inner{ 
            padding: 0px 0px 555px 0px;
        }

}
}
@media (min-width:912px) {
    .home-header{
        .header-inner{ 
            padding: 0px 0px 620px 0px;
        }
      
}
}
@media (min-width:1000px) {
    .home-header{
        .header-inner{ 
            padding: 0px 0px 300px 0px;
            clip-path: none;
        }

}
}
