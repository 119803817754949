.notification-wrapper {
    padding-top: 100px;

    .no-notification{
        text-align: center;
    }
    
    .notification-title {
        text-align: center;
        margin-bottom: 60px;
    }
h6{
    color: var(--ion-color-primary);
    margin: 0 20px 20px 0;
    text-align: end;
}
    .notifications-content {
        .msg-read{
            background: #fff;
        }
        ion-item {
            padding: 0 30px 0 30px;
            &:last-child{
                --inner-border-width: 0;
            }  
            .msg-content {
                padding: 12px 0px 12px 0px;
                h6{
                    margin: 0 0 0 10px !important;
                    text-align: end;
                }
                h4{
                    font-size: 14px !important;
                }
                p{
                    font-size: 12px !important;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    max-width: 250px;
                }
            }
            ion-icon {
                font-size: 25px;
                margin-left: auto !important;
                display: block;
            }
        }
    }
}
.read-msg{
    margin: 0 !important;
}