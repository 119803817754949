.edit-profile-wrapper{
    padding: 100px 20px 0 20px;
    .profile-image{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        ion-avatar {
            border: 6px dashed var(--ion-color-primary);
            width: 130px;
            height: 130px;
            padding: 2px;
            position: relative !important;
    
        }
    
        .edit_pencile {
            background: #0C1935;
            // color: #BBBFC6;
            padding: 10px;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            position: absolute;
            top: 100px;
            left: 50%;
            box-shadow: white 0px 0px 0px 4px;
            transform: translateX(-50%);
    
            ion-img {
                width: 50px;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%) scale(-1);
            }
        }
    }
  .profile-list{
    ion-item{
        ion-label{
            &:first-child{
                display: inherit;
            }
        }
    }
  }
}