.desktop_booking_type {
    background-color: var(--ion-color-tertiary);

    ion-grid {
        padding: 0;

        .desktop_booking-rightbar {
            background-color: #F8F8F9;
            padding: 40px 100px;
            width: 850px;
            border-radius: 25px 0 0 25px;
            margin: auto;

            .desktop_page_title {
                font-size: 25px;
                text-align: center;
                text-transform: capitalize;
                // margin-bottom: 20px;
            }

            .desktop_select_boxes {
                justify-content: center;
                margin: 20px 0;

                ion-buttons {
                    display: flex;
                    flex-direction: column;
                    // align-items: flex-start;
                    background: white;
                    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
                    border-radius: 10px;
                    padding: 40px 13px;
                    width: 100%;
                    height: 170px;

                    button {
                        background: transparent;

                        ion-img {
                            width: 60px;
                            // position: fixed;
                            bottom: 6px;
                            padding-top: 8px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 414px) {
    .desktop_booking_type {
        .desktop_booking-rightbar {
            width: 100%;
            border-radius: 25px 0 0 0px !important;
            padding: 60px 20px !important;
        }

        .desktop_select_boxes {
            ion-buttons {
                padding: 13px !important;
                width: 100%;
                height: 123px !important;
            }
        }
    }
}