.desktop-home {
  ion-grid {
    ion-row {
      transition: all ease-in-out 0.5s;
    }
    .home-inner {
      display: flex;
      justify-content: center;
      align-items: center;
    
      h5 {
        margin-top: 16px !important;
        text-align: center;
      }
      ion-col{
        padding: 5px !important;
      }
      // .delivery_boxes {
      //   margin: 10px 0;

      //   ion-buttons {
      //     display: flex;
      //     flex-direction: column;
      //     justify-content: center;
      //     background: white;
      //     box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
      //     border-radius: 10px;
      //     width: 100%;
      //     padding: 25px;
      //     height: 250px;
      //     transition: all ease-in-out 0.5s;
      //     &:hover {
      //       transform: translateY(-15px);
      //     }
      //     h6,p {
      //       text-align: center;
      //     }
      //     div {
      //       background: transparent;
      //       margin-bottom: 5px;
      //       ion-img {
      //         width: 40px;
      //       }
      //     }
      //   }
      // }
    }
  }
}
@media (min-width: 992px) {
  .desktop-home{
    .delivery_boxes {
      ion-col {
        padding-right: 16px;
        &:nth-child(3) {
          padding-right: 0;
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .desktop-home {
    .home-inner {
      // height: 100%;
      .desktop-delivery-boxes {
        ion-buttons {
          &:hover {
            transform: translateX(0px) !important;
          }
        }
      }
    }
    .rightbar-none{
      display: none !important;
    }
  }
}
