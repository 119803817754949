.dispatch-wrapper{
    padding: 20px;
    h2{
        margin: 0px 0 10px 10px !important;
    }
    ion-row{
        justify-content: center;
        ion-col{
            padding: 10px;
        }
    }
    .card{
        text-align: center;
        padding: 20px;
        border-radius: 10px;
        box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
        height: 122px;
        position: relative;
        ion-badge{
            position: absolute;
            right: -5px;
            top: -10px;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 10px;
        }
        ion-img{
            width: 40px;
            margin: 0 auto;
        }
        h6{
            margin: 7px 5px 0 !important;
            line-height: 15px !important;
        }
    }
}