.document-wrapper {
    background-color: #f9f9f9;
    // height: 100%;
    .driver-document-detail{
        padding-top: 120px !important;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
   
    .document-title {
        text-align: center;
        padding-bottom: 30px;
        margin: 0px;
    }

    .document-box{
        padding: 0px;
        background-color: white;
        box-shadow: rgb(0 0 0 / 24%) 0px -8px 10px -1px;
        border-radius: 35px 35px 0 0;
        padding: 30px 20px;
         height: 100%;
        ion-row {
            justify-content: space-between;

            ion-col {
                padding: 0;
                .space-box {
                    padding: 10px 15px;
                    .empty_boxes {
                        background-color: #f9f9f9;
                        border-radius: 10px;
                        width: 100%;
                        height: 150px;
                        display: flex;
                        justify-content: center;
                        ion-icon{
                         font-size: 44px;
                         padding: 4px;
                         color: white;
                         background-color: #E0E7E3;
                         border-radius: 50%;
                        }
                     }
                    .category_boxes {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 10px 20px;
                        background: #f9f9f9;
                        border-radius: 10px;
                        width: 100%;
                        height: 150px;
                        position: relative;
                        ion-buttons {
                            width: 100%;
                            // text-align: center;
                            display: flex;
                            flex-direction: column;
                            align-items: left;

                            ion-img {
                                width: 60px;
                                height: 60px;
                                text-align: center;
                                &:first-child{
                                    width: 30px;
                                    height: 30px;
                                    position: absolute;
                                    right: -33px;
                                    top: -33px;
                                }
                            }
                            p{
                                padding: 10px 0px 0px;
                                margin-right: auto;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                width: 90px;
                                white-space: nowrap;
                            }

                            .date-set {
                                text-decoration: none;
                                font-size: 13px;
                                font-weight: 500;
                                color: black;
                                padding: 0;
                                margin-right: auto;
                            }
                        }

                        ion-button {
                            width: 100%;
                            height: 100%;
                            margin: 0px;
                        }
                    }  
                    h6{
                        // text-align: center;
                        padding-top: 10px;
                        text-align: center;
                    }
                    
                }

            }
        }
    }
}

}


.sc-ion-buttons-ios-h {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: left;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    z-index: 99;
}