.driver-profile-wrapper{
    padding: 120px 20px 20px;
    h2{
        text-align: left;
    }
    .profile-header{
        background: #ededed;
        border-radius: 10px;
        padding: 20px;
        margin: 20px 0;
        text-align: center;
        .avatar-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;        
            ion-avatar {
                border: 6px dashed var(--ion-color-primary);
                width: 130px;
                height: 130px;
                padding: 2px;
                position: relative !important;
                margin-bottom: 13px;
            }
        
            .edit_pencile {
                background: #0C1935;
                // color: #BBBFC6;
                padding: 10px;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                position: absolute;
                top: 100px;
                left: 50%;
                box-shadow: white 0px 0px 0px 4px;
                transform: translateX(-50%);        
                ion-img {
                    width: 50px;
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%) scale(-1);
                }
            }

        }
        ion-button{
            height: 25px;
        }
        .driver-fname{
            margin-top: 4px !important;
        }
    }
    .profile-disc{
        .item_detail {
            position: relative !important;
            margin: 8px 0!important;
            width: 100% !important;
    
            ion-label {
                font-size: 15px;
                margin-bottom: 10px;
            }
    
            ion-item {
                background: #ededed !important;
                border-radius: 10px !important;
                padding: 0 18px !important;
                border-bottom: none;
                margin-top: 10px;
                ion-input {
                    font-size: 14px !important;
                    --padding-top: 0px;
                    --padding-bottom: 0px;
                }
            }
            .select-item{
                padding: 0 5px !important;
            }
        }
    }
    .address-wrapper{
        ion-icon{
            color: var(--ion-color-tertiary);
        }
        div{
            padding-left: 5px;
            width: 100%;
            ion-input{
                --padding-top: 8px;
                --padding-bottom: 8px;
            }
        }
        
    }
}