.sidebar {
  width: 100%;
  .sidebar-logo {
    ion-img {
      display: none;
      margin-left: 54px;
    }
  }
  .double-arrow {
    width: 70px;
    background: rgba(0, 0, 0, 0.25);
    padding: 17px;
    border-bottom-right-radius: 80px 80px;
    ion-img {
      width: 15px !important;
      transform: rotate(270deg);
      transition: all ease-in-out 0.6s;
    }
  }
  .menu {
    padding: 40px 10px 10px 40px;
    text-transform: uppercase;
    overflow-y: auto;
    height: 80vh;
    .menu-inner{
      .icon-width{
      .dot{
        background: #fff;
        border: 2px solid #fff;
        box-sizing: border-box;
        border-radius: 100px;
      }
    }
    }
      h5 {
        color: #f8f8f9;
        margin-left: 10px !important;
        margin-bottom: 0px !important;
        font-size: 14px !important;
        cursor: pointer;
      }
      p{
        color: #f8f8f9;
        margin-top: 10px;
        text-transform: capitalize;
        margin-left: 50px;
      }
   
  }

  .dots-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    .dots {
      background: #fff;
      border: 2px solid #fff;
      box-sizing: border-box;
      border-radius: 100px;
      margin: 0 20px;
    }
    ion-icon{
      color: #fff;
    }
    .hide-upper-menu {
      transition: transform 0.6s ease-in-out;
      transform: translateY(-50px);
    }
    .show-upper-menu {
      transition: transform 0.6s ease-in-out;
      transform: translateY(0%);
    }
  }
  
}
.icon-width{
  width: 32px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
.icon-wrapper{
  display: flex;
  background: #30AF74;
  padding: 10px;
  border-radius: 50%;
}
}
.login-btn{
  color: #fff;
  text-transform: uppercase;
  padding-left: 55px;
  position: fixed;
  bottom: 10px;
  cursor: pointer;
}
@media (min-width: 992px) {
  .sidebar {
    .sidebar-logo {
      margin-top: 40px;
      ion-img {
        display: block;
        width: 160px;
      }
    }
    .double-arrow {
      display: none;
    }
    .mini-sidebar {
      height: 0px;
    }
    .dots-wrapper {
      display: none;
    }
  }

}
@media (max-width:991px){
  .sidebar{
    .mini-sidebar {
      // max-height: 48px;
      width: 100%;
      height: 48px;
      transition: all 0.6s ease-in-out;
      .double-arrow {
        ion-img {
          width: 25px;
          transform: rotate(90deg);
        }
      }
    }
    .auto-sidebar {
      height: 100vh;
      transition: all 0.6s ease-in-out;
    }
  
  }
}
@media screen and (min-width:992px) and (max-width: 1100px){
  .sidebar{
    .sidebar-logo {
      ion-img {
        margin-left: 22px !important;
      }
    }
    .menu {
      padding: 40px 10px 10px 10px !important;
    }
  }
}