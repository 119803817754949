.header_top {
  position: relative;
  .header_toolbar {
    &::before {
      background: var(--ion-color-secondary);
      content: " ";
      padding: 110px;
      clip-path: circle(85.8% at -36% 15%);
    }
    .header_back_button {
      --background: white !important;
      --border-radius: 50% !important;
      --box-shadow: rgb(0 0 0 / 16%) 0px 4px 22px;
      width: 60px;
      height: 60px;
      position: absolute;
      left: 23px;
      top: 46px;
      ion-icon {
        color: #595959cc;
        font-size: 24px;
      }
    }
    .header_img {
          width: 150px;
          position: absolute;
          left: 0;
    }
  }
}
.header_home_button {
  background: #fff !important;
  box-shadow: rgb(60 64 67 / 30%) 0px 6px 9px 0px, rgb(60 64 67 / 15%) 0px 2px 6px 2px;
  width: 45px;
  height: 45px;
  transform: rotate(45deg);
  position: absolute;
  right: 24px;
  top: 24px;
  border: 2px solid var(--ion-color-primary);
  padding: 3px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  ion-img {
    width: 30px;
    transform: rotate(315deg);
  }
}