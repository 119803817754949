.view-order-wrapper {
  padding-top: 100px;

  .wrapper-inner {
    background-color: #f9f9f9;
    .timeline-tabs {
      background: #fff;
      div{
        font-weight: 600;
        div:nth-child(1){
          width: 50%;
          text-align: center;
        }
        div:nth-child(2){
          width: 50%;
          text-align: center;
        }
      }
      
    }
    .timeline-wrapper {
      position: relative;
      &::before {
        content: "";
        width: 50%;
        height: 4px;
        background: var(--ion-color-secondary);
        position: absolute;
        right: 0;
      }

      .planning-timeline {
        padding: 20px;

        ul.timeline-list {
          li {
            ion-icon {
              color: var(--ion-color-tertiary);
              border: 2px solid var(--ion-color-tertiary);
              border-radius: 50%;
              position: absolute;
              left: 10px;
              background: white;
              z-index: 1;
              --ionicon-stroke-width: 100px;
              padding: 2px;
            }
            .timeline-detail {
              padding: 0 20px 14px 32px;
              border-left: 2px solid var(--ion-color-tertiary);
              position: relative;

              &.pending {
                border-left: none;

                &:before {
                  content: "";
                  border: 2px solid var(--ion-color-primary);
                  width: 20px;
                  height: 20px;
                  position: absolute;
                  left: -10px;
                  border-radius: 50%;
                  background: white;
                }
              }
            }
            .pending-top {
              padding: 0 20px 14px 32px;
              border-left: 2px solid var(--ion-color-primary);
              position: relative;
            }
          }
        }
      }
    }
    .info-wrapper {
      position: relative;
      &::before {
        content: "";
        width: 50%;
        height: 4px;
        background: var(--ion-color-secondary);
        position: absolute;
        left: 0;
      }
    }
  }
}
.desktop-view-order-wrapper{
  padding-top: 0 !important;
}