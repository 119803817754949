.order-buttons-wrapper{
    text-align: center;
    ion-button{
        margin: 10px;
    }
    .secondary-btn{
        --background: var(--ion-color-secondary);
        color: #fff;
    }
    .trans-btn{
        --background: #fff;
        color: #000;
    }
  
}
.order-overview-modal {
    --height: fit-content;
    --border-radius: 6px;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
    padding: 0 20px;
    // ion-item{
    //     background: #fff;
    //     box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
    //     border-radius: 10px;
    //     margin: 16px 0;
    // }
    // ion-img{
    //     width: 150px;
    //     margin: 0 auto;
    // }
    .delay-template-box{
        display: flex;
        flex-wrap: wrap;
        .delay-reason-box{
            font-size: 14px;
            padding: 5px 10px;
            background-color: #f1f1f1;
            border-radius: 20px;
            margin-right: 4px;
            margin-top: 6px;
        }
        .delay-active-reason{
            font-size: 14px;
            padding: 5px 10px;
            background-color: #f1f1f1;
            border-radius: 20px;
            margin-right: 4px;
            margin-top: 6px;
            border: 2px solid #30AF74;
        }
    }
    .reason-textarea-box{
        padding-top: 10px;
        .reason-textarea{
            max-height: 150px;
            height: 200px;
            min-width: 100%;
            max-width: 100%;
            border: 1px solid rgb(202, 202, 202);
            border-radius: 5px;
            display: none;
        }
        .reason-textarea:focus{
            outline: #ccc;
        }
    }
    .spinner-wrapper{
        background: #fff;
        height: 50px;
        ion-spinner{
            top:50%;
            left:50%;
            position: fixed;
            transform: translate(-50%, -50%);
            color: var(--ion-color-primary);
        }
    }
    ion-searchbar{
        padding: 10px 0 !important;
    }
    .driver-list{
        height: 230px;
        overflow: auto;
        ion-item{
            &:last-child{
              --border-style: none !important;
            }
          }
        ion-avatar{
            margin: 10px 16px 10px 0;
        }
        h5{
            margin-bottom: 0;
        }
    }
  }

  .sc-ion-select-popover-md-h ion-list.sc-ion-select-popover-md {
    margin: 8px;
  }
  ion-popover::part(backdrop) {
    --background-color: none;
    }   