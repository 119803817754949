.customer-sidebar{
    background: var(--ion-color-tertiary);
    h4{
        color: #fff;
    }
    .customer-menu{
        ion-icon{
            color: #fff;
        }
        span{
            text-transform: uppercase;
            color: #fff;
            font-size: 12px;
            cursor: pointer;
        }
        .menu-title{
            font-weight: 700;
            font-size: 14px;
        }
    }
    .menu-item-inner{
        cursor: pointer;
        display: flex;
        align-items: center;
    }
}