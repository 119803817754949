.order-rejection-details-wrapper{
    padding-top: 100px;
    h2{
        text-align: center;
    }
    .inner-wrapper{
        background-color: #fff;
        margin-top: 30px;
        padding: 20px;
        box-shadow: rgb(0 0 0 / 22%) 0px 19px 27px 16px;
        border-radius: 35px 35px 0 0;
        min-height: 75vh;
        .buttons-wrapper{
            text-align: center;
            margin: 20px 0 40px;
            ion-button{
                margin: 0 10px;
                &:nth-child(2){
                    color: #FE502D;
                    --border-color: #FE502D;
                }
            }
        }
        .rejection-details{
                ion-label{
                    font-size: 14px;
                    font-weight: 700;
                }
                h6{
                    flex-grow: 1;
                }
                span{
                    font-weight: 700;
                    font-size: 14px;
                    color: var(--ion-color-primary);
                    text-decoration: underline;
                }
                h3{
                    margin: 20px 0 10px !important;
                }
        }
    }
}
.order-btns{
        text-align: center;
        margin-bottom: 20px;
        ion-button{
            margin: 0 10px;
            &:nth-child(2){
                color: #FE502D;
                --border-color: #FE502D;
            }
        }
}
.transfer-card{
    background-color: #fff;
    padding: 24px 15px;
    margin: 10px 20px;
    border-radius: 6px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .driver-details{
        display: flex;
        align-items: center;
        gap: 10px;
    }
}
.down-arrow-img-wrapper{
    display: flex;
    justify-content: center;
    ion-img{
        width: 35px;
        height: 50px;
    }
}