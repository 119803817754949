.navbar{
    background-color: #0B58AB;
    ion-img{
        width: 175px;
    }
ul{
    display: flex;
    justify-content: end;
    text-transform: uppercase;
    color: #fff;
    li{
        padding: 15px 7px;
        cursor: pointer;
    }
}
}
@media screen and (max-width: 350px) {
    .navbar{
        ion-col{
            width: 100% !important;
        }
    }
  }