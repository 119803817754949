.driver-rejection-wrapper{
    padding: 100px 20px 0;
    h2{
        margin-bottom: 12px;
    }
    ion-searchbar{
        input{
          padding-left: 20px !important;
          padding-right: 0px !important;
          border-radius: 5px !important;
          &::placeholder{
            font-size: 15px !important;
          }
        }
        ion-icon{
          padding: 0 10px;
          right: 0 !important;
          left: auto !important;
          border-radius: 0 5px 5px 0;
          background: var(--ion-color-primary);
          clip-path: polygon(18% 0, 100% 0, 100% 100%, 0% 100%);
          color: #fff !important;
          top: 0;
          width: 21px;
          height: 100%;
        }
      }
    .search-icon{
        padding: 20px;
        position: absolute;
        top: 0;
        right: 0;
      }
    .scan-icon{
        position: absolute;
        top: 9px;
        right: 50px;
        font-size: 25px;
        color: #868686;
    }  
    .searchbar-search-icon.sc-ion-searchbar-md {
        top: 1px;
        padding: 10px;
    }
    ion-icon.scan-icon.ios{
        top: 5px;
    }
    .sc-ion-searchbar-ios-h {
        height: 34px;
    }
    .card{
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 20px;
        margin: 20px 0;
        border-radius: 15px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        background-color: #fff;
        .image-wrapper{
            width: 70px;
        }
        ion-label{
            font-size: 14px;
            font-weight: 700;
        }
        p{
            width: 130px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0;
        }
    }
}
