.job-history {
    padding: 100px 20px 20px 20px;
    background-color: #F9F9F9;
    height: 100%;

    .job-history-content {
        margin-top: 20px;

        .driver-performance1 {
            margin: 20px 0px 0px 0px;
            border: 1px solid #DFDFDF;
            padding: 20px 18px;
            border-radius: 10px;
            box-shadow: rgb(0 0 0 / 30%) 4px 6px 6px;

            h3 {
                margin: 0;
                padding-bottom: 5px;
            }

            .order-detail {
                display: flex;
                align-items: center;
                justify-content: space-between;

                h5 {
                    margin: 0;
                }
            }
        }

        .driver-performance {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 20px 0px 0px 0px;
            border: 1px solid #DFDFDF;
            padding: 20px 15px;
            border-radius: 10px;
            box-shadow: rgb(0 0 0 / 30%) 4px 6px 6px;

            h5 {
                margin: 0;
            }

            // Ion-Button {
            //     width: 75px;
            // }

            .order-btn {
                width: 75px;
                background-color: var(--ion-color-primary);
                border-radius: 20px;
                text-align: center;

                p {
                    color: white;
                    padding: 8px 14px;
                }
            }
        }

        .job-timing {
            margin-top: 20px;
            text-align: center;

            h3 {
                margin: 0;
                padding-bottom: 5px;
            }

        }

        .vehicle-type {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 20px 0px;

            h3 {
                margin: 0;
            }

            p {
                font-size: 17px;
            }
        }
    }
}