.driver-sidebar-profile {
    padding: 50px 30px 0px 30px;
    background-color: var(--ion-color-tertiary);
    height: 100vh;

    .driver-profile {
        display: flex;
        align-items: center;
        margin-right: auto;
        margin-left: 0px;
        // margin: 0px 0px 0px 20px;


        .avatar-img {
            display: flex;
            align-items: center;
            width: 80px;

            ion-img {
                width: 80px !important;
                height: 80px !important;
            }

        }

        .profile-content {
            margin-left: 18px;
            word-break: break-word;
            h4 {
                color: white;
            }

            p {
                color: white;
            }
        }
    }

    .side-profile-list {
        padding: 60px 0 0 0 !important;

        ion-icon {
            // color: var(--ion-color-tertiary);
            color: white;
        }

        ion-label {
            margin-left: 15px;
            // color: black;
            color: white;
        }

    }
}