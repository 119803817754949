.manage-driver-wrapper{
    padding: 120px 20px 20px;
    h2{
        text-align: left;
        margin-bottom: 10px;
    }
    ion-searchbar{
        input{
          padding-left: 20px !important;
          padding-right: 0px !important;
          border-radius: 5px !important;
          &::placeholder{
            font-size: 15px !important;
          }
        }
        ion-icon{
          padding: 0 10px;
          right: 0 !important;
          left: auto !important;
          border-radius: 0 5px 5px 0;
          background: var(--ion-color-primary);
          clip-path: polygon(18% 0, 100% 0, 100% 100%, 0% 100%);
          color: #fff !important;
          top: 0 !important;
          width: 21px;
          height: 100% !important;
        }
      }
      .search-icon{
        padding: 20px;
        position: absolute;
        top: 0;
        right: 0;
      }
    .card{
        padding: 15px 5px;
        margin: 15px 0;
        border-radius: 7px;
        background: #ededed;
        .driver-row{
            align-items: center;
        }
        .driver-image{
            ion-img{
                width: 70px;
                &::part(image) {
                    border-radius: 10px;
                  }
            }
        }
        .driver-disc{
            display: flex;
            justify-content: space-between;
            align-items: center;
            h4{
                margin-bottom: 0px;
                line-height: 19px !important;
            }
            ion-button{
                --background: var(--ion-color-tertiary);
                height: 25px;
                font-size: 12px;
                text-transform: capitalize !important;
            }
        }
    }
}