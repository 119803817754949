.driver-panel {
    // background: rgb(255, 255, 255);
    // background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(214, 203, 203, 0.6979166666666667) 100%);
    padding: 12px 0px 0px !important;
    background-color: #F0F5FB;
    background: url('../images/bg-1.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: top right;
    // background-color: red;

    .driver-panel-header {
        display: flex;
        justify-content: end;
        margin-right: 20px;

        .notification {
            display: flex;

            .notifications-icon-btn {
                font-size: 22px;
                background: white;
                padding: 12px;
                border-radius: 50%;
                box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
                margin-right: 12px;

                &::after {
                    content: "";
                    width: 9px;
                    height: 9px;
                    border-radius: 50%;
                    position: absolute;
                    background: var(--ion-color-primary);
                    top: 15px;
                    right: 14px;

                }
            }

            // .menu-btn {
            //     ion-img {
            //         width: 45px;
            //         background-color: white;
            //         padding: 8px;
            //         border-radius: 50%;
            //         box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
            //     }
            // }
        }
    }

    .driver-profile {
        display: flex;
        align-items: center;
        margin-right: auto;
        margin: 30px 0px 0px 20px;
        // margin-left: 10px;

        .avatar-img {
            display: flex;
            align-items: center;

            ion-img {
                width: 50px !important;
                height: 50px !important;
            }
        }
 
        .profile-content {
            margin-left: 5px;
        }
    }

    .panel-content {
        margin-top: 20px;
        text-align: center;
        // background-color: var(--ion-color-tertiary);
        border-radius: 30px 30px 0px 0px;
        background: url('../images/bg-2.jpg');
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: top left;

        h3 {
            margin-bottom: 0px;
            color: white;
            padding: 20px 0px;

        }

        .Driver-link {
            padding: 0px;
            background-color: white;
            border-radius: 30px 30px 0px 0px;
            padding: 30px 20px;

            ion-row {
                justify-content: space-between;

                ion-col {
                    padding: 0;

                    .space-box {
                        padding: 10px;
                        .category_boxes {
                            box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            background: white;
                            // box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
                            border: 1px solid #DFDFDF;
                            border-radius: 10px;
                            width: 100%;
                            height: 120px;
                            padding: 0;
                            position: relative;
                                ion-badge{
                                    position: absolute;
                                    right: -5px;
                                    top: -10px;
                                    width: 25px;
                                    height: 25px;
                                    border-radius: 50%;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    font-size: 10px;
                                }
                                ion-buttons {
                                    width: 100%;
                                    text-align: center;
                                    display: flex;
                                    flex-direction: column;

                                    ion-img {
                                        width: 50px;
                                        height: 50px;
                                    }

                                    p {
                                        text-decoration: none;
                                        font-size: 17px;
                                        font-weight: 500;
                                        color: black;
                                        padding-top: 10px;
                                        margin: auto;
                                    }
                                }

                            ion-button {
                                width: 100%;
                                height: 100%;
                                margin: 0px;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (min-width:1300px) {
    .driver-panel{
        padding: 55px 50px 0 50px !important;
    }
}
        
ion-modal{
    padding: 0 20px;
    .driver-modal-wrapper{
        padding: 20px;
        position: relative;
        span{
            padding: 5px 10px 8px 10px;
            font-size: 20px;
            line-height: 17px;
            background: #D3D3D3;
            color: #333333;
            border-radius: 50%;
            position: absolute;
            top: 10px;
            right: 10px;
          }
        h1{
            margin: 0 0 10px 0 !important;
        }
            ion-img{
                width: 150px;
                margin: 0 auto;
            }
        }
}