.view-meetup-wrapper{
    padding-top: 120px;
    h2{
        text-align: center;
        margin-bottom: 20px;
    }
    .single-proof-multi-points{
        display: flex;
    }
    .single-proof-multi-points{
        padding: 0px 20px;
    }
    .single-proof-multi-points p {
        display: flex;
        align-items: center;
        color: #30AF74 !important;
        // text-decoration: underline;
        font-weight: bold;
        // padding-bottom: 12px;
    }
    .single-proof-multi-points .all-notall{
        text-decoration: underline;
        margin-right: 12px;
    }
    .single-proof-multi-points .close-multi-point-selection {
        background-color: #fff;
        border-radius: 100%;
        border: 3px solid #fff;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        padding: 0px 1px 0px 1px;
    }
    .single-proof-multi-points .save-multi-point-selection{
        background-color: #fff;
        border-radius: 100%;
        border: 3px solid #30AF74;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        padding: 0px 1px 0px 1px;
    }
    .single-proof-multi-points .selection-decision{
        margin-left: 10px;
    }
    .meetup-points-box{
        margin-top: 12px;
    }
    .meetup-cards-wrapper{
        padding: 0 20px;
        .meetup-card{
            background-color: #fff;
            border: 1px solid #F8F8FD;
            border-top: 2px solid var(--ion-color-primary);
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            border-radius: 5px;
            padding: 20px;
            margin-bottom: 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            ion-icon{
                color: var(--ion-color-tertiary);
            }
            h6{
                color: #827d7d;
            }
        } 
    }
}