
.wrapper{
    padding: 100px 20px 0!important;
    .page_title{
        color: var(--ion-heading-color);
        padding-top: 10px;
    }
    .local_services{
        display: flex;
        justify-content: space-around;
        padding: 0 0 0 20px;
        .booking_btn{
            --background: var(--ion-color-primary) !important;
            width: 100% !important;
            height: 58px !important;
            margin-right: 10px;
            text-transform: capitalize;
            .btn_content{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                ion-icon{
                    font-size: 22px;
                }
                ion-label{
                    font-size: 14px;
                    padding-top: 5px;
                }
            }
        }
    }
    .order_list{
        padding:25px 0 30px 0;
        .list_label{
            display: flex;
            align-items: center;
            ion-badge{
                border-radius: 50%;
                width: 30px;
                height: 30px;
                justify-content: center;
                align-items: center;
                display: flex;
            }
            h4{
                padding-left: 10px !important;
            }
        }
        .list_inputs{
            padding-left: 40px;
        }
        &::before{
            content: "";
            background: var(--ion-heading-color);
            width: 2px;
            height: 210px;
            position: absolute;
            top: 60px;
            left: 13px;
        }
    }
    .order_list2{
        padding:25px 0 30px 0;
        .list_label{
            display: flex;
            align-items: center;
            ion-badge{
                border-radius: 50%;
                width: 30px;
                height: 30px;
                justify-content: center;
                align-items: center;
                display: flex;
            }
            h4{
                padding-left: 10px !important;
            }
        }
        .list_inputs{
            padding-left: 40px;
        }
    }
    .select_parcel{
        h4{
            padding: 10px 5px !important;
        }
        .parcel_item{
            border: 2px solid var(--ion-color-primary);
            border-radius: 10px;
            ion-select::part(icon) {
                display: none;
               }
               .chevronDownOutline{
                position: absolute;
                top: 0;
                bottom: 0;
                font-size: 25px;
                right: 10px;
                margin: auto;
              }
        }
    }
    .parcel_weight{
        padding: 10px 5px !important;
        ion-row{
            ion-col{
                ion-button{
                    --background: var(--ion-color-primary) !important;
                    width: 100% !important;
                }
            }
        }
    }
    .net_amount{
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        padding: 10px;
        border-radius: 10px;
        margin-top: 20px;
        background: white;
    }
    .next_btn{
        --background: var(--ion-color-primary) !important;
        width: 100%;
        margin:20px 0;
    }
}