.customer-profile-wrapper{
    padding-top: 100px;
    .imge_profile{
        ion-avatar {
            border: 6px dashed var(--ion-color-primary);
            width: 130px;
            height: 130px;
            padding: 2px;
            position: relative !important;
            margin-bottom: 18px;
        }
}
    .edit_pencile {
        background: #0C1935;
        // color: #BBBFC6;
        padding: 10px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: absolute;
        top: 100px;
        left: 50%;
        box-shadow: white 0px 0px 0px 4px;
        transform: translateX(-50%);

        ion-img {
            width: 50px;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%) scale(-1);
        }
    }
    .wrapper-inner {
        margin-top: 25px;
        .history-tabs {
            background-color: #f9f9f9;
          div{
            font-weight: 600;
            div:nth-child(1){
              width: 50%;
              text-align: center;
            }
            div:nth-child(2){
              width: 50%;
              text-align: center;
            }
          }
          
        }
        .history-wrapper {
          position: relative;
          &::before {
            content: "";
            width: 50%;
            height: 4px;
            background: var(--ion-color-secondary);
            position: absolute;
            right: 0;
          }
        }
        .info-wrapper {
          position: relative;
          &::before {
            content: "";
            width: 50%;
            height: 4px;
            background: var(--ion-color-secondary);
            position: absolute;
            left: 0;
          }
        }
      }
}
.customer-details {
    padding: 40px 20px 0;
    h4{
        color: #595959c2;
    }
}
.history-details {
    padding: 20px;
  }