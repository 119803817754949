.order-overview-wrapper{
    padding-top: 100px;
    h2{
        text-align: center;
    }
    ion-button{
        margin: 10px;
        &:nth-child(2){
            --background: #fff;
            color: #000;
        }
    }
    .booking-card{
        background-color: #fff;
        margin: 16px 0;
        .pickup-side-border{
            border-left: 2px solid #000;
            padding-left: 20px;
            position: relative;
            p{
                margin-bottom: 16px;
                &::before{
                    content: "";
                    width: 16px;
                    height: 16px;
                    position: absolute;
                    top: -2px;
                    left: -9px;
                    border-radius: 50%;
                    background: var(--ion-color-primary);
                }
                &:nth-child(2){
                    &::after{
                        content: "";
                        width: 16px;
                        height: 16px;
                        position: absolute;
                        bottom: -2px;
                        left: -9px;
                        border-radius: 50%;
                        background: var(--ion-color-primary);
                    }
                }
            }
        }
        .dropoff-side-border{
            border-left: 2px solid #000;
            padding-left: 20px;
            position: relative;
            p{
                margin-bottom: 16px;
                &::before{
                    content: "";
                    width: 16px;
                    height: 16px;
                    position: absolute;
                    top: -2px;
                    left: -9px;
                    border-radius: 50%;
                    background: var(--ion-color-tertiary);
                }
                &:nth-child(2){
                    &::after{
                        content: "";
                        width: 16px;
                        height: 16px;
                        position: absolute;
                        bottom: -2px;
                        left: -9px;
                        border-radius: 50%;
                        background: var(--ion-color-tertiary);
                    }
                }
            }
        }
        h4{
            margin-bottom: 10px !important;
        }
    }
    .map-card{
        h4{
            margin-bottom: 16px !important;
        }
    }
}