.error-wrapper{
    position: relative;
    height: 100vh;
    div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 100vh;
        padding: 16px;
        ion-img{
            width: 100px;
            margin: 0 auto;
            margin-bottom: 30px;
        }
        h1{
            font-size: 120px;
            color: var(--ion-color-tertiary);
            margin-bottom: 30px;
            font-weight: 400 !important;
            line-height: 90px;
        }
        .text{
            text-transform: uppercase;
            font-size: 35px;
            margin-bottom: 25px;
            line-height: 40px;
        }
        a{
            color: var(--ion-color-tertiary);
            
        }
    }
}