.proof-image-modal{
    padding: 0px;
    .modal-content{
        padding: 20px 0px;
        background-color: #000;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
    }
}
.modal-header{
    display: flex;
    justify-content: flex-end;
    padding: 0px 10px;
    color: #fff;
    position: absolute;
    top: 10px;
    right: 2px;
}
.modal-actions{
    padding: 20px;
    h5{
        color: #fff;
        text-align: center;
    }
}
.order-receiving-wrapper{
    padding-top: 130px;
    .inner-wrapper{
        h3{
            font-size: 25px;
            text-align: center;
            text-transform: capitalize;
        }
        .body{
            min-height: 90vh;
            padding: 20px;
            background-color: #feffff;
            border-radius: 35px 35px 0 0;
            box-shadow: rgb(48 175 116 / 40%) 0px 3px 30px, rgb(48 175 116 / 15%) 0px 7px 37px, rgb(48 175 116 / 15%) 0px 0px 0px;
            ion-label{
                font-weight: 600;
            }
            ion-input{
                margin: 10px 0 25px;
                outline: 1px solid var(--ion-color-step-350);
                border-radius: 8px;
            }
            input.native-input.sc-ion-input-ios {
                padding-left: 8px;
            }
            .col-clear{
                display: flex;
                justify-content: flex-end;
            }
            .col-sign-text{
                display: flex;
                align-items: center;
            }
            .signature-canvas{
                // width: 400px;
                // height: 100%;
                border: 1px solid #000;
                box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                border-radius: 8px;
                margin: 10px 0;
            }
            .card{
                height: 200px;
                box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                border-radius: 8px;
                margin: 10px 0;
            }
            .avatar-cards{
                ion-row{
                    margin: 3px 0 15px;
                    ion-col{
                        div{
                            padding: 10px;
                            border-radius: 10px;
                            background-color: #F4F4F4;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            min-height: 110px;
                            position: relative;
                            ion-avatar{
                                width: 50px;
                                height: 50px;
                            }
                            h6{
                                margin: 5px !important;
                                word-break: break-all;
                            }
                            p{
                                margin: 5px 0px !important;
                                word-break: break-all;
                            }
                            .remove-icon{
                                position: absolute;
                                top: -5px;
                                right: -5px;
                                background-color: #FE502D;
                                color: #fff;
                                border-radius: 50%;
                                font-size: 25px;
                            }
                            ion-icon{
                                font-size: 40px;
                            }
                            span{
                                background-color: #fff;
                                padding: 2px;
                                border-radius: 50%;
                                display: flex;
                                align-items: center;
                            }
                        }
                    }
                }
            }
        }
    }
}