.order-history-wrapper{
    position: relative;
    min-height: 100vh;
    background-color: #f9f9f9;
.history-header{
    padding: 120px 30px 0 30px;
}

.order-list{
    padding: 30px;
    background: #fff;
    box-shadow: rgb(0 0 0 / 22%) 0px 19px 27px 16px;
    border-radius: 35px 35px 0 0;
    min-height: 70vh;
    margin-top: 50px;
    .success-icon{
        background: var(--ion-color-primary);
        border-radius: 50%;
        width: 40px;
        padding: 8px;
        margin-right: 16px;
      }
      .pending-icon{
        background: var(--ion-color-tertiary);
        border-radius: 50%;
        width: 40px;
        padding: 8px;
        margin-right: 16px;
      }
      ion-item{
        --inner-padding-end: 0px;
        ion-icon{
            color: var(--ion-color-tertiary);
        }
        ion-label{
            h2{
                line-height: 30px;
                margin-bottom: 0;
            }
        }
      }
      .order-list-item{
        margin: 15px 0;
        .order-no{
          line-height: 14px;;
        }
        .order-no-number{
            line-height: 16px;
        }
        .order-status{
            color: var(--ion-color-primary);
        }
      }
}
}
