.transfer-jobs-btn-wrapper{
    display: flex;
    justify-content: end;
    margin-right: 20px;

    div{
        display: flex;
        align-items: center;
        gap: 6px;
        background-color: #fff;
        box-shadow: rgb(48 175 116 / 30%) 0px 3px 4px, rgb(48 175 116 / 15%) 0px 7px 37px, rgb(48 175 116 / 15%) 0px 0px 0px;
        padding: 4px 12px 4px 7px;
        border-radius: 50px;
        span{
            padding: 10px;
            background-color: rgba(48, 175, 116,.1);
            border-radius: 50%;
            ion-img{
                width: 20px;
            }
        }
        h6{
            font-size: 14px !important;
            color: #333;
        }
    }
}
.driver-job-content {
    padding: 64px 20px 0px 20px;

    .driver-job-header {
        display: flex;
        align-items: center;
        margin-right: auto;
        // margin-left: 10px;

        .avatar-img {
            display: flex;
            align-items: center;

            ion-img {
                width: 50px !important;
                height: 50px !important;
            }
        }

        .profile-content {
            margin-left: 10px;
        }
    }

    .assign-job {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 20px 10px;
    }
}